@import '../../scss_partials/config';
.session-detail-container {
    & h4 {
        margin: 0px;
        font-size: size(xl);
        font-weight: weight(heavy);
        color: color(light);

    }
    .session-card-container h4 {
        color: #fff;
    }
}
.session-expert-container {
    padding: 20px;
    // padding-bottom: 0px;
    background-color: color(main-background);
    border-radius: 10px;
    margin-bottom: 20px;

    & .expert-thumbnail-container {
        display: flex;
        align-items: center;
        margin: 20px 0px;
        margin-bottom: 0px;
        & img {
            height: 70px;
            width: 70px;
            object-fit: cover;
            border-radius: 50px;
            margin-right: 10px;
        }
        & h5 {
            font-size: size(large);
            margin: 0px;
            font-weight: 600;
            color: color(white7);
        }
        & .expertise {
            color: color(white5);
            display: inline-block;
            width: 100%;
            font-size: size(regular);
            font-weight: weight(medium);
        }
    }
}
.language-tag-container {
    display: flex;
    flex-wrap: wrap;

    & .language-tag {
        flex-shrink: 1;
        background-color: #1c1c1c;
        margin-right: 10px;
        font-size: size(sm);
        font-weight: weight(bold);
        padding: 10px 8px;
        border-radius: 10px;
        color: color(white7);
        display: flex;
        align-items: center;
        margin-bottom: 10px;


        &:last-child {
            margin-right: 0px;
        }

        // & img {
        //     height: 15px;
        //     margin-right: 5px;
        // }
        & .language-icon {
            font-size: 14px;
            margin-right: 5px;
        }
    }
}
.session-detail-container {
    & .language-tag-container {
        margin-bottom: 10px;  
    }
}

// Session Detail Col Style

.session-info-container {
    width: 100%;

    & .session-image {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 15px;
    }
    & h1 {
        font-size: size(xxl);
        font-weight: weight(bold);
        margin-top: 25px;
        margin-bottom: 15px;
        font-family: font(roboto);
        color: color(light);
    }
    & p {
        font-size: size(regular);
        font-weight: weight(medium);
        color: color(white7);
    }

    & .timer-container {
        display: inline-flex;
        align-items: center;
        border: 1px solid #242424;
        border-radius: 7px;
        padding: 10px 20px;
        & .timer-icon {
            height: 25px;
            margin-right: 7px;
        }
        & .timer-icon-light {
            height: 25px;
            margin-right: 7px;
            display: none;
        }
        & .duration {
            font-size: size(regular);
            font-weight: weight(bold);
            color: color(white7);
            white-space: nowrap;
        }
    }
    & .book-now-container {
        padding: 20px 25px;
        background-color: color(background2);
        margin: 40px 0px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        & .price-container {
            flex: 1;
            & .price-content-container {
                padding-right: 25px;
                border-right: 2px solid color(background3);
                display: inline-flex;
                align-items: center;
                &:last-child {
                    margin-left: 25px;
                    border-right:none;
                    // & img {
                    //     height: 19px;
                    // }
                }

                & .video-icon {
                    height: 15px;
                    object-fit: contain;
                    margin-right: 7px;
                }
                & .map-pin {
                    height: 19px;
                    margin-right: 7px;
                }
                & .video-icon-light {
                    height: 15px;
                    object-fit: contain;
                    margin-right: 7px;
                    display: none;
                }
                & .map-pin-light {
                    height: 19px;
                    margin-right: 7px;
                    display: none;
                }
                & .price {
                    font-size: size(large);
                    font-weight: weight(bold);
                    color: color(light);
                    white-space: nowrap;
                }
            }
        }
    }
    & .more-sessions-container {
        & h5 {
            font-size: size(xxl);
            font-weight: weight(bold);
            margin-bottom: 5px;
        }
        & .session-card-wrapper {
            margin-bottom: 10px;
        }
    }
    & .btn-container {
        & div {
            background-color: #0E0E0E;
        }
    }
}
.availabilty-tabs-container {
    // background-color: red;
    // padding: 15px;
    border: 1px solid color(background3);
    border-radius: 10px;
    overflow: hidden;
    margin: 15px 0px;

    & .availabilty-tabs-content-container {
        // background-color: #fff;
        border-bottom: 1px solid color(background3);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;

        & .next-prev-btn {
            background-color: color(background3);
            font-size: 10px;
            height: 25px;
            width: 25px;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: color(light);
        }

        & label {
            font-size: size(sm);
            color: color(light);
            font-weight: weight(medium);
            margin: 0px;
            flex: 1;
            text-align: center;
            cursor: pointer;
        }
    }

    & .MuiTab-root {
        width:100%;
        background-color: color(background2);
        text-transform: capitalize;
        font-size: size(sm);
        font-weight: weight(bold);
    }
    & .MuiTabScrollButton-root {
        background-color: color(background2);
        width: auto;
    }
    & .MuiPaper-elevation1 {
        background-color: color(background2);
        border-bottom: 1px solid color(background3);
    }
    & .MuiSvgIcon-root {
        color: color(light);
        background: color(black7);
        border-radius: 100px;
    }
    & .MuiTab-textColorPrimary.Mui-selected {
        color: color(light);
    }
    & .MuiTab-textColorPrimary {
        color: color(white5);
    }
    & .MuiTabs-root {
        padding-left: 15px;
        padding-right: 15px;
    }
    & .PrivateTabIndicator-colorPrimary-2 {
        display: none;
    }
    & .time-slots-container {
        // padding: 15px;
        // padding-right: 30px;
        // padding-bottom: 0px;
        padding: 5px 20px;
        height: 170px;
        overflow: scroll;

        & .row {
            // height: 100%;
        }

        & .time-slots-content-container {
            // padding-right: 0px;
            padding: 0 5px;
        }

        & .time-slot-pills {
            background-color: color(background3);
            margin: 5px 0px;
            font-size: size(sm);
            display: flex;
            justify-content: center;
            flex: 1;
            padding: 8px 0px;
            border-radius: 5px;
            color: color(light);
        }
    }
}

// Social Media Styles

.social-media-container {
    display:flex;
    margin-top: 10px;
    & .social-media {
        height:40px;
        width: 40px;
        background-color: #1c1c1c;
        // background-color: color(background3);
        border-radius: 20px;
        margin-right: 12px;
        display: flex;
        justify-content:center;
        align-items:center;
        cursor: pointer;
        transition: all ease .3s;
        & .social-share-btn {
            width: 100%;
            height: 100%;
        }
        &:hover {
            // transform: ;
            transform: scale(1.2) rotate(-360deg);
            transition: all ease .3s;
        }
        
        &:first-child:hover {
            background-color: #007bb6;
            color: #fff;
        }
        &:nth-child(2):hover {
            color: #fff;
            background-color: #bb0000;
        }
        &:nth-child(3):hover {
            color: #fff;
            background-color: #bb0000;
        }
        &:nth-child(3):hover {
            color: #fff;
            background: #f09433; 
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
            background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
        }
        &:last-child:hover {
            color: #fff;
            background-color: #3b5998;
        }
    }
}

// light mode specific styles
.app-theme-light  {
    .session-detail-container {
        & h4 {
            color: red;
    
        }
        .session-card-container h4 {
            color: #fff;
        }
        & .btn-container {
            & div {
                background-color: color(light-background2);
                color: #000;
            }
        }
    }
    .session-expert-container {
        background-color: color(light-main-background);
        box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.08);
        -webkit-box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.08);
        -moz-box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.08);
        & .expert-thumbnail-container {
            & h5 {
                color: color(black7);
            }
            & .expertise {
                color: color(black5);
            }
        }
    }
    .language-tag-container {
        & .language-tag {
            background-color: color(light-background2);
            border: 1px solid color(light-background3);
            color: color(black7);
        }
    }
    .session-info-container {
        & h1 {
            color: color(dark);
        }
        & p {
            color: color(black7);
        }
    
        & .timer-container {
            border: 2px solid color(light-background2);
            & .timer-icon {
                display: none;
            }
            & .timer-icon-light {
                display: inline-block;
            }
            & .duration {
                color: color(black7);
            }
        }
        & .book-now-container {
            background-color: color(light-background2);
            & .price-container {
                & .price-content-container {
                    border-right: 2px solid color(black10);
                    &:last-child {
                        border: none;
                    }
                    & .video-icon {
                        display: none;
                    }
                    & .map-pin {
                        display: none;
                    }
                    & .video-icon-light {
                        display: inline-block;
                    }
                    & .map-pin-light {
                        display: inline-block;
                    }
                }
                & .price {
                    color: color(dark);
                }
            }
        }

            
    }
    & .more-sessions-container {
        & h5 {
            color: color(dark);
        }
    }
    .availabilty-tabs-container {
        border: 1px solid color(light-background3);
    
        & .availabilty-tabs-content-container {
            border-bottom: 1px solid color(light-background3);
    
            & .next-prev-btn {
                background-color: color(light-background2);
                color: color(dark);
            }
            & label {
                color: color(dark);
            }
        }
    }
    & .MuiTab-root {
        background-color: color(light-background2);
    }
    & .MuiTabScrollButton-root {
        background-color: color(light-background2);
    }
    & .MuiPaper-elevation1 {
        background-color: color(light-background2);
        border-bottom: 1px solid color(light-background3);
    }
    // & .MuiSvgIcon-root {
    //     color: color(dark);
    //     background: color(white7);
    // }
    & .MuiTab-textColorPrimary.Mui-selected {
        color: color(dark);
    }
    & .MuiTab-textColorPrimary {
        color: color(black5);
    }
    & .time-slots-container {
        color: color(dark);
        & .time-slot-pills {
            background-color: color(light-background2);
            color: color(dark);
        }
    }
    & .social-media-container {
        & .social-media {
            background-color: color(black10);
            color: #252525;
        }
    }
}

#app-locale-ar {
    & .social-media-container .social-media {
        margin-right: 0px;
        margin-left: 10px;
    }
    & .session-expert-container .expert-thumbnail-container img {
        margin-right: 0px;
        margin-left: 10px;
    }
    & .availabilty-tabs-container .availabilty-tabs-content-container .next-prev-btn {
        transform: scaleX(-1);
    }
    & .session-info-container .timer-container img {
        margin-right: 0px;
        margin-left: 7px;
    }
    & .session-info-container .book-now-container .price-container .price-content-container {
        padding-right: 0px;
        padding-left: 25px;
        border-right: none;
        border-left: 2px solid color(background3);
    }
    & .session-info-container .book-now-container .price-container .price-content-container:last-child {
        border-left: none;
    }
    & .session-info-container .book-now-container .price-container .price-content-container img {
        margin-right: 0px;
        margin-left: 7px;
    }
    & .session-info-container .book-now-container .price-container .price-content-container:last-child {
        margin-left: 0px;
        margin-right: 25px;
    }
    .language-tag-container .language-tag:first-child {
        margin-right: 0px;
        margin-left: 10px;
    }
    .language-tag-container .language-tag .language-icon {
        margin-right: 0px;
        margin-left: 5px;
    }
}