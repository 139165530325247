@import './../../scss_partials/config';

.header-container {
    // max-height: 70vh;
    background-color: color(main-background);
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: bottom;
    background-size: cover;
    // min-height: 500px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: -81px;

    & .header-content-container {
        display: flex;
        justify-content: center;
        height:100%;
        flex-direction: column;
        align-items: center;
        text-align: center;

        & h1 {
            font-size: 54px;
            // margin: 40px 0 0px 0;
            margin-bottom: 20px;
            color: color(light);
            font-weight: weight(bold);
        }
        & p {
            width: 50%;
            color: color(white5);
            margin-bottom: 0px;
            margin-top: 10px;
            font-weight: 600;
            margin: 0px;
            // font-weight: weight(medium);
        }
    }
}
.faq-page-container {

    background-color: color(background2);

    & .faq-row {
        margin-top: 130px;
    }
    
    & .faq-page-content-container {
        // padding-top:70px;
        // padding-bottom: 70px;
        padding-left: 0px;
        padding-right: 0px;

        & .download-banner-wrapper {
            padding-top: 0px;
        }

        & .faq-title-container {
            & h3 {
                font-size: size(xxl);
                color: color(light);
            }
            & .faq-title-border {
                height:5px;
                width:70%;
                margin-bottom: 25px;
                margin-top: -25px;
            }
        }
        & .faq-container {
            margin-top: -25px;
            & .accordion>.card {
                background-color: color(background2);
                border-bottom: 2px solid color(background3);
                border-left: none;
                border-right: none;
                border-top: none;

                & svg {
                    color: color(white5);
                    font-size: size(sm);
                }
            }
        
            
            & .card-header {
                // padding-top: 25px;
                // padding-bottom: 25px;
                padding: 25px 0px;
                background: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                padding-right: 5px;
                border: none;
                
                & h6 {
                    color: color(light);
                    margin: 0px;
                    flex: 1;
                    font-weight: 800;

                }
            }
            & .card:first-child .card-header {
                border-top: 2px solid color(background3);
            }
            & .card-body {
                
                padding: 15px 0px;
                padding-bottom: 25px;
                padding-top: 0px;
                
                & p {
                    margin: 0px;
                    font-size: size(regular);
                    color: color(white5);
                    font-weight: 600;
                }
            }
        }
    }
}

.assistive-banner-wrapper {
    background-color: color(main-background);
    position: relative;
    padding-top: 0;
    padding-bottom: 200px;

    .assistive-banner-container {
        background-color: color(main-background);
        & img {
            height: 300px;
        }
        & .assistive-banner-content-container {
            & h4 {
                font-size: 32px;
                margin-bottom: 25px;
                width: 50%;
                color: color(light);
                font-weight: 800;
                line-height: 1.7;
            }
            // background-color: red;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}
.new-line {
    white-space: pre-line;
}

.app-theme-light {
    .header-container {
        background-color: color(light-background2);
        background-image: url('./../../assets/images/faq-bg-light.png') !important;
    
        & .header-content-container {
            & h1 {
                color: color(dark);

            }
            & p {
                color: color(black5);

            }
        }
    }
    .faq-page-container {

        background-color: color(light-main-background);
        
        & .faq-page-content-container {
    
            & .faq-title-container {
                & h3 {
                    color: color(dark);
                }
            }
            & .faq-container {
                & .accordion>.card {
                    background-color: color(light-main-background);
                    border-color: color(light-background3);
                }
            
                
                & .card-header {
                    & h6 {
                        color: color(dark);
                    }
                    & svg {
                        color: color(black5);
                    }
                }
                & .card:first-child .card-header {
                    border-color: color(light-background3);
                }
                & .card-body {
                    & p {
                        color: color(black5);
                    }
                }
            }
        }
    }
    .assistive-banner-wrapper {
        background-color: color(light-background2);
        .assistive-banner-container {
            background-color: color(light-background2);
            & .assistive-banner-content-container {
                & h4 {
                    color: color(dark);
                }
            }
        }
    }
}

#app-locale-ar {
    & .faq-page-container {
        & .faq-page-content-container {
            & .faq-row {
                text-align: right;
            }
        }
    }
    & .assistive-banner-wrapper .assistive-banner-container {
        text-align: right;
    } 
    .assistive-banner-wrapper .assistive-banner-container img {
        transform: scaleX(-1);
    }
}