@import './../../scss_partials/config';

.section-title {
    margin-bottom: 50px;
    color: color(light);
    font-family: font(raleway);
    font-weight: weight(700);
    font-size: size(xxl);
}

@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.2; }
    100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.2; }
    100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.2; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.2; }
    100% { opacity:1; }
}
.animate-flicker {
    -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}

// @keyframes fadeIn { 
//     from { opacity: 0.2; }
// }
// @-o-keyframes fadeIn{
//     from { opacity: 0.2; }
// }
// @-moz-keyframes fadeIn{
//     from { opacity: 0.2; }
// }
// @-webkit-keyframes fadeIn{
//     from { opacity: 0.2; }
// }
// .animate-flicker {
//     -webkit-animation: fadeIn 0.6s infinite alternate;;
//     -moz-animation: fadeIn 0.6s infinite alternate;;
//     -o-animation: fadeIn 0.6s infinite alternate;;
//     animation: fadeIn 0.6s infinite alternate;
// }

.home-container {
    background-image: url('./../../assets/images/diagram-group.png');
    background-repeat: no-repeat;
    background-size: contain;
    & .platform-download-container .download-platform{
        color: color(light);
        border: 1px solid color(light)
    }
    & .download-platform:hover {
        color: color(golden-yellow);
    }
    padding-right: 0px;
    padding-bottom: 290px;
    padding-top: 25px; /*navbar height*/
    & .slider-container {
        height: 350px;
        margin-top: 30px;
        margin-bottom: 50px;
        min-height: 290px;
        // transition: all ease 500ms;

        & .slider-content-container {
            height: 100%;
            align-items: center;

            & .slider-info-container {
                & h2 {
                    // transition: all ease 300ms;
                    color: color(light);
                    font-size: size(xxl);
                    font-weight: weight(bold);
                }
                & p {
                    font-size: size(medium);
                    width: 85%;
                    // font-weight: weight(medium);
                    margin: 15px 0px;
                    margin-bottom: 25px;
                    color: color(white7);
                }
            }

            & .slides-container {
                height: 100%;
                padding-right: 0px;

                & .slick-list, .slick-list .slick-track, .slick-list .slick-track .slick-slide > div {
                    height: 100%;
                }
                & .slides-wrapper {
                    // background-color: #333;
                    height: 100%;
                    margin-right: 15px;
                    width: 50vw!important;
                }
            }
        }
    }
}

// Features Section Styles

.features-section {
    // background-image: url('./../../assets/images/bg-shape-1.svg');
    background-repeat: no-repeat;
    background-position: top;
    color: #000;
    padding-top: 0px;
    padding-bottom: 230px;
    position: relative;
    // background-color: #ef725b;
    
    & .features-container {
        padding: 0 15px;
        max-width: 380px;
        & img {
            height: 70px;
            margin-bottom: 25px;
        }
        & h5 {
            font-size: size(xl);
            font-weight: weight(heavy);
            margin: 0px;
        }
        & p {
            font-size: size(medium);
            max-width: 90%;
            margin-top: 10px;
            color: color(black7);
            font-weight: 500;
        }
    }
}


// Experts Section Styles

.experts-section {
    padding-bottom: 100px;
    padding-top: 100px;
    background-color: color(background2);
    // transition: all ease 300ms;
    padding-right: 0px;
    padding-left: 0px;
    
    & p {
        font-size: size(medium);
        color: color(white7);
        margin-bottom: 40px;
        font-weight: 500;;
    }
    & h2 {
        color: color(light);
        font-size: size(xxl);
        font-weight: weight(heavy);
    }
}
.expert-card-parent {
    margin: 0 10px;
}

.arrows-top {
    .slick-arrow-prev,
    .slick-arrow-next {
        top: -90px;
        // &:hover {
        //     background-color: #959595;
        //     // transition: all ease-in-out 0.5s;
        // }
        // &:hover .carousal-icon {
        //     color: #2B2B2B;
        //     // transition: all ease-in-out 0.25s;
        //     transform: scale(0.75);
        // }
    }
    .slick-arrow-prev {
        // right: 19vw;
        right: 65px;
    }
    .slick-arrow-next {
        // right: 15vw;
        right: 0px;
    }
}
.category-container {
    padding: 0 15px;
    position: relative;
     .category-prev-arrow-bg, 
     .category-next-arrow-bg {
        position: absolute;
        top: 0;
        height: 100%;
        width: 20px;
    }
        
    & .category-prev-arrow-bg {
        left: 0px;
        background: linear-gradient(to left, rgba(20, 20, 20, 0), color(main-background), color(main-background), color(main-background) 135%);
    }
    & .category-next-arrow-bg {
        right: 0px;
        background: linear-gradient(to right, rgba(20, 20, 20, 0), color(main-background), color(main-background), color(main-background) 135%);
        width: 70px;
    }
    .slick-arrow-prev,
    .slick-arrow-next {
        top: 0px;
        height: 65px;
        border-radius: 0;
        width: unset;
        background-color: #242424;
    }
    .slick-arrow-prev {
        left: -30px;
        background: linear-gradient(to left, rgba(20, 20, 20, 0), color(main-background), color(main-background), color(main-background) 100%);
        // left: -37px;

    }
    .slick-arrow-next {
        right: -30px;
        background: linear-gradient(to right, rgba(20, 20, 20, 0), color(main-background), color(main-background), color(main-background) 100%);
        // right: -52px;
    }
    .carousal-icon {
        color: color(light);
        font-size: 16px;
    }
}
.testimonial-arrows-parent {
    .slick-arrow-prev,
    .slick-arrow-next {
        background-color: rgba(0, 0, 0, 0.1);
        top: 40%;
    }
    .slick-arrow-prev {
        left: 0px;
    }
    .slick-arrow-next {
        right: 0px;
    }
    .carousal-icon {
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
    }
}
.arrows-bottom {
    .slick-arrow-prev,
    .slick-arrow-next {
        bottom: -30px;
        &:hover {
            background-color: #959595;
            // transition: all ease-in-out 0.5s;
        }
        &:hover .carousal-icon {
            color: #2B2B2B;
            // transition: all ease-in-out 0.25s;
            transform: scale(0.75);
        }
    }
    .slick-arrow-prev {
        right: 75px;
    }
    .slick-arrow-next {
        right: 20px;
    }
}


// Session Section Styles

.category-slider {
    margin-top: 50px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
}
.home-page {
    & .session-section {
    
        & .session-card-wrapper {
            margin-bottom: 0px;
            // margin-bottom: 30px;
            color: color(light);

            & .session-card-container {
                margin-bottom: 15px;
            }
        }
        & h3 {
            color: color(light);
            margin-bottom: 30px;
        }
    }

    & .category-card {
        margin: 0px 10px;
    } 
}


// Article Section Styles

.article-container {
    & h3 {
        margin-bottom: 40px;
        color: color(light);
    }
    & .arrows-bottom {
        & .article-card-container {
            margin-bottom: 30px;
        }
    }
}

// About Walsos Section Styles

.about-walsos-section {
    & .video-card-wrapper {
        // background-color: red;
        margin-right: 25px;
        width: 50vw !important;
        max-width: 720px;

        & .video-card-container .video-card-content-container h6 {
            font-size: size(xl);
            font-weight: weight(heavy);
        }
        & .video-card-container .video-card-overlay {
            padding: 25px
        }
    }
    & .arrows-bottom {
        & .video-card-wrapper {
            margin-bottom: 30px;
        }
    }

}
.video-modal  {
    // & .MuiPaper-root {
    //     width: 100%;
    //     height: 100%;
    // }
    & .MuiDialog-paperWidthFalse {
        width: 70%;
        height: 70%;
    }
    & .MuiDialog-paperFullWidth {
        height: 500px;
    }
    & .MuiBackdrop-root {
        background-color: rgba(0,0,0,0.5);
        backdrop-filter: blur(10px);
        &:hover {
            // background-color: #fff;
        }
    }
    & .MuiDialog-container {
        cursor: url('./../../assets/images/close-cursor.png'), auto;

    }
}


// About Walsos Section Styles

.videos-section {
    position: relative;
    & .videos-tabs-container {
        display: flex;
        margin-bottom: 40px;
        overflow: auto;
        white-space: nowrap;

        & .tab-btn {
            background-color: #242425;
            margin-right: 10px;
            padding: 10px 20px;
            border-radius: 11px;
            color: #888888;
            cursor: pointer;
            text-decoration: none;
        }

        & h3 {
            margin: 0px 0px;
            padding: 0px 25px;
            cursor: pointer;
            // transition: all ease 800ms;
            
            &:active {
                transform: scale(0.9);
                // transition: all ease 300ms;
            }
            &:first-child {
                padding-left: 0px;
            }
            
        }
    }
    & .video-card-wrapper {
        margin-bottom: 30px;
    }
}


// Testimonial Section Styles

.testimonial-section {
    // background-image: url('./../../assets/images/bg-shape-1.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    color: #000;
    // padding-top: 150px !important;
    padding-bottom: 270px !important;
    // background-color: color(golden-yellow);
    position: relative;
    
    & .testimonials-slide-container {
        // background-color: red;
    }
    & .testimonials-slide-content-container {
        // background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        & img {
            height: 80px;
            width: 80px;
            border-radius: 100px;
            object-fit: cover;
            border: 1px solid #F4725C;
        }
        
        & p {
            width: 50%;
            text-align: center;
            margin: 35px 0px;
            color: rgba(0, 0, 0, 0.7);
            font-size: size(regular);
            font-weight: 700;
        }
        & h6 {
            color: color(pinkk);
            margin: 0px;
            font-weight: weight(bold);
            font-size: size(regular);
        }
    }
    
    & .download-wrapper {
        margin-top: 120px;
        // margin-bottom: 100px;
    }
}


// Download Banner Styles

.download-banner-container {
    border-radius: 8px;
    overflow: hidden;
    background-color: color(dark);
    padding: 50px;
    color: color(light);
    background-image: url('./../../assets/images/download-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right -470px top -225px;
    
    & h3 {
        margin: 0px;
        font-size: size(xxxl);
    }
    
    & p {
        font-size: size(medium);
        font-weight: weight(medium);
        color: color(white7);
        margin-top: 15px;
        margin-bottom: 25px;
    }
}
.download-banner-inverse {
    background-color: color(golden-yellow);
    
    & h3 {
        color: #2d2d2d;
    }
    & p {
        color: rgba(0, 0, 0, 0.7);
    }
}

.slick-arrow-prev,
.slick-arrow-next {
    background-color: color(white10);
    position: absolute;
    // top: 48%;
    width: 50px; 
    height: 50px;
    border-radius: 50%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.carousal-icon {
    color: color(white5);
    font-size: 15px;
}

// .arrows-bottom .slick-dots {
//     text-align: left;
// }
// .arrows-bottom .slick-dots ul {
//     padding: 0;
//     display: flex;

//     & li {
//         display: inline-flex;
//         align-items: center;
//     }
// }
// .custom-dots {
//     height: 15px;
//     width: 15px;
//     background-color: #242424;
//     border-radius: 50%;
// }
// .slick-active .custom-dots {
//     background-color: #828282;
// }
.arrows-bottom {
    .slick-dots {
        bottom: -60px;
        text-align: left;
        & ul {
            padding: 0 15px;
            display: flex;
            & li {
                display: inline-flex;
                align-items: center;
                width: unset;
                margin: 0 3px;
                & .custom-dots {
                    height: 12px;
                    width: 12px;
                    background-color: color(background3);
                    border-radius: 50%;
                }
            }
            & .slick-active {
                & .custom-dots {
                    background-color: color(dots-active);
                }
            }
        }
    }
}
.slick-list> div {
    margin: 0;
}
.features-arrow {

}

@media (min-width: 768px) { 
    .features-arrow {
        & .slick-dots {
            display: none;
        }
        & .slick-arrow-prev {
            display: none;
        }
        & .slick-arrow-next {
            right: -20px;
            top: 32%;
            border: 2px solid color(pinkk);
            background-color: color(golden-yellow);
            height: 60px;
            width: 60px;
        }
        & .carousal-icon {
            color: color(pinkk);
            font-size: 14px;
            margin-left: 2px;
        }
    }
    .features-arrow.features-pink-arrow {
        & .slick-arrow-next {
            border: 2px solid color(navy-blue);
            background-color: color(pinkk);
        }
        & .carousal-icon {
            color: color(navy-blue);
        }
    }
}


.app-theme-light {
    & .home-container {
        background-color: color(light-background2);
    }
    & .videos-section {
        & .videos-tabs-container .tab-btn {
            color: color(dark);
            background-color: #f1f1f1;
        }
    }
    & .home-container .slider-container .slider-content-container .slider-info-container h2,
    & .home-container .slider-container .slider-content-container .slider-info-container p {
        color: color(dark)
    }
    & .experts-section {
        background-color: color(light-background2);

        & h2 {
            color: color(dark);
        }
        & p {
            color: color(black7);
        }
    }
    & .slick-arrow-prev,
    & .slick-arrow-next {
        background-color: color(black10);
    }
    & .carousal-icon {
        color: color(dark);
    }
    & .category-prev-arrow-bg {
        background: linear-gradient(to left, rgba(255, 255, 255, 0), color(light), color(light), color(light) 135%);
    }
    & .category-next-arrow-bg {
        background: linear-gradient(to right, rgba(255, 255, 255, 0), color(light), color(light), color(light) 135%);
    }
    & .arrows-bottom .slick-dots ul li .custom-dots {
        background-color: color(black10);
    }
    & .arrows-bottom .slick-dots ul .slick-active .custom-dots {
        background-color: color(light-dots-active);
    }
    & .section-title {
        color: color(dark);
    }
    & .category-container {
        & .slick-arrow-prev,
        & .slick-arrow-next {
            background: none;
        }
    }
    & .download-banner-container {
        background-color: color(light-background2);
        color: #252525;
        background-image: url('./../../assets/images/download-bg-light.png');
        & h3 {
            font-size: size(xxxl);
            color: #2d2d2d;
        }
        & p {
            color: color(black7);
        }
    }
    & .download-banner-container.btn-light-switch {
        & .platform-download-container {
            & .download-platform {
                background-color: #2d2d2d;
                color: color(light-background2);
                border-color: color(light-background2);
                &:hover {
                    color: color(olive-green);
                    border-color: color(olive-green);
                }
            }
        } 
    }
    .download-banner-inverse {
        background-color: color(golden-yellow);
        
        & h3 {
            color: #2d2d2d;
        }
        & p {
            color: rgba(0, 0, 0, 0.7);
        }
    }
}

#app-locale-ar {
    & .home-page {
        & .slider-info-container,
        & .section-title {
            text-align: right;
        }
        & .home-container .slider-container .slider-content-container .slides-container {
            padding-right: 15px;
            padding-left: 0px;
        }
        & .home-container {
            & .slick-slider {
                transform: scaleX(-1);
            }
            padding-right: 15px;
            padding-left: 0px;

            // & .arrows-bottom .slick-arrow-prev {
            //     right: auto;
            //     left: 20px;
            // }
            // & .arrows-bottom .slick-arrow-next {
            //     right: auto;
            //     left: 70px;

            // }
        }
        & .features-section .features-container {
            direction: rtl;
            text-align: right;
        }
        @media (min-width: 768px) { 
            .features-arrow {
                & .slick-arrow-next {
                    right: auto;
                    left: -20px;
                    top: 32%;
                    transform: scaleX(-1);
                }
                & .carousal-icon {
                    margin-right: 2px;
                }
            }
        }
        & .experts-section h2,
        & .experts-section p {
            text-align: right;
        }
        // & .experts-section.arrows-top .slick-arrow-prev {
        //     right: auto;
        //     left: 15vw;
        // }
        // & .experts-section.arrows-top .slick-arrow-next {
        //     right: auto;
        //     left: 19vw;
        // }
        & .session-section h3 {
            text-align: right;
        }
        & .category-container {
            transform: scaleX(-1);
            & .category-card {
                transform: scaleX(-1);
            }
        }
    }
    & .section-title {
        font-family: 'Cairo', sans-serif;
    }
    & .session-section .session-section-content-container .session-card-container {
        transform: scaleX(-1);
    }
    & .experts-section .expert-card-container,
    & .experts-section .slick-slider,
    & .session-section .session-section-content-container .slick-slider,
    & .about-walsos-section .slick-slider,
    & .about-walsos-section .slick-slider .video-card-container  {
            transform: scaleX(-1);
    }
    & .session-section .arrows-bottom .slick-dots,
    & .article-container .arrows-bottom .slick-dots{
        display: flex;
        justify-content: flex-end;
    }
    & .session-section .arrows-bottom .slick-arrow-next,
    & .article-container .arrows-bottom .slick-arrow-next {
        right: auto;
        left: 75px;
    }
    & .session-section .arrows-bottom .slick-arrow-prev,
    & .article-container .arrows-bottom .slick-arrow-prev {
        right: auto;
        left: 20px;
    }
    & .download-banner-container {
        text-align: right;
        background-position: left -470px top -225px;
    }
    & .about-walsos-section {
        .video-card-container .video-card-content-container {
            flex-direction: row-reverse;
        }
    }
    & .videos-section .videos-tabs-container .tab-btn {
        margin-right: 0px;
        margin-left: 10px;
    }
}
@import './HomeResponsive.scss';