@import '../../scss_partials/config';
@import './navigation-bar.scss';

@media (max-width: 575.98px) {
    .nav-container {
        padding-left: 0px;
        padding-right: 0px;
        // background-color: red;
        width: 100vw;
        overflow-x: hidden;
        overflow: visible;

        & .MuiFormControl-root {

            & .MuiSelect-select {
                padding-left: 0px !important;
                // background-color: red;
            }
        }

        & .navbar.custom-navbar  {
            & .dark-mode-switcher {
                // padding-left: 0px !important;
                // background-color: red;
            }
            & .nav-link {
                padding-left: 0px;
            }
            & .navbar-brand {
                flex: 1;
                display: flex;
            }
            & .responsive-country-dropdown {
                // background-color: red;
                // line-height: 10px;
                & .dropdown-toggle {
                    padding-left: 0px;
                    padding-right: 0px;
                }

                & .dropdown-toggle  {
                    // background-color: red;
                    line-height: 0px;
                }
                & .dropdown-menu {
                    margin: 0px;
                    width: 100vw;
                    right: -108px;
                    height: 100vh;
                }
            }
        }
    }
}

#app-locale-ar {
    @media (max-width: 575.98px) {
        .nav-container {
            & .navbar-brand {
                margin-right: 0px;
            }
            & .country-dropdown {
                text-align: right;
                padding-right: 0px;
                & .btn-primary {
                    padding-right: 0px;
                }
            }
            & .MuiFormControl-root {
                // background-color: red;
                width: 100%;
                display: flex;
                align-items: flex-start;
                padding-right: 0px;

                & .MuiSelect-select {
                    padding-right: 0px;
                }
            }
            & .navbar.custom-navbar {
                & .dark-mode-switcher {
                    // padding-left: 14px;
                    // background-color: red;
                }
                & .nav-link {
                    text-align: right;
                    padding-right: 0px;
                }
                & .dark-mode-switcher {
                    padding-right: 15px;
                }
                & .responsive-country-dropdown {
                    & .dropdown-menu {
                        margin: 0px;
                        width: 100vw;
                        right: auto;
                        left: -108px;
                        height: 100vh;
                    }
                }
            }
        }
    }
}