@import '../../scss_partials/config';

.download-page-container {
    background-color: color(background2);
    & .header-container {
        background-image: url('../../assets/images/download-bg-diagram.png') !important;
        background-color: color(background2);
        height: 220px;

        & h1 {
            margin-bottom: 0px;
        }
    }
    & .download-platform-container {
        padding-bottom: 250px;
        color: color(light);
    }
    & .download-platform-image-container {
        height: 350px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        & .platform-hardware {
            width: 200%;
            height: 200%;
            object-fit: contain;
            position: relative;
            left: -420px;
            top: 20px;
        }

        & .download-platform-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
            // background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
            // background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
            
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#101010+0,101010+100&0.1+0,0.5+100 */
            background: -moz-linear-gradient(top,  rgba(16,16,16,0.1) 0%, rgba(16,16,16,0.5) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(16,16,16,0.1) 0%,rgba(16,16,16,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(16,16,16,0.1) 0%,rgba(16,16,16,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a101010', endColorstr='#80101010',GradientType=0 ); /* IE6-9 */

            backdrop-filter: blur(3px);
            cursor: pointer;
            isolation: isolate;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;

            & img {
                height: 100px;
                width: 100px;
                right: auto;
                left: auto;
                // object-fit: contain;
            }

            &:hover {
                // background: red;
                mix-blend-mode: screen;
                background-color: color(golden-yellow);

                // & img {
                //     display: none;
                // }
            }
        }
    }
    & .assistive-banner-wrapper .assistive-banner-container .assistive-banner-content-container {
        & img {
            height: auto;
            // background-color: red !important;

        }
        & .btn-container div {
            height: 70px;
            background-color: transparent;
        }

        & h4 {
            width: 100%;
        }
    }
    & .download-platform-col {
        & .download-platform-content-container {
            border-radius: 15px;
            overflow: hidden;
            background-color: #141414;
        }
        &> a {
            color: color(light);
            text-decoration: none;
        }
        &:last-child {
            & .download-platform-image-container img {
                right: 60px;
                left: auto;

            }
            
        }
        & .platform-info-container {
            padding: 25px;
            display: flex;
            align-items: center;
            flex-direction: column;

            & h4 {
                font-size: size(xxl);
                font-weight: 700;
            }

            & a {
                text-decoration: none;
                color: color(white7);
            }
            & span {
                height: 25px;
                width: 25px;
                position: relative;
                left: 15px;

                & svg path#download-icon {
                    stroke: color(golden-yellow);
                }
            }

            & .direct-download-container {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                & img {
                    height: 25px;
                    position: relative;
                    left: 15px;
                }
            }
        }
    }
}

.app-theme-light {
    .download-page-container {
        background-color: color(light-background2);
        & .header-container {
            background-color: color(light-background2);
        } 
        & .download-platform-container {
            color: color(dark);
        }
        & .download-platform-content-container {
            background-color: #ededed;
        }
        & .download-platform-col {
            & .platform-info-container a {
                color: color(black7);
            }
            &> a {
                color: color(dark);
            }
            & .platform-info-container span svg path#download-icon {
                stroke: color(pinkk);
            }
        }
        & .assistive-banner-wrapper,
        & .assistive-banner-wrapper .assistive-banner-container {
            background-color: color(light);
        }
        & .download-platform-image-container .download-platform-overlay:hover {
            background-color: color(pinkk);
        }
    }
}

#app-locale-ar {
    .download-page-container {
        & .platform-info-container .direct-download-container img {
            left: auto;
            right: 15px;
        }
        & .download-platform-image-container .platform-hardware {
            transform: scaleX(-1);
        }
        & .download-platform-image-container .platform-hardware {
            left: auto;
            right: -420px;
        }
        & .download-platform-col:last-child .download-platform-image-container img {
            right: auto;
            left: 60px;
        }
        & .download-platform-col .platform-info-container span {
            left: auto;
            right: 15px;
        }
    }
}