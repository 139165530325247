@import './../../scss_partials/config';

.article-link:hover {
    color: unset;
    text-decoration: none;
}
.article-card-container {
    & .article-card-row {
        align-items: stretch;
        margin: 0;
        text-decoration: none;
        & .article-card-col {
            display: flex;
            padding: 0;
        }
    }
    & img {
        width: 100%;
        height: 386px;
        overflow: hidden;
        object-fit: cover;
    }
    & .first-image {
        height: 400px;
    }
    & .article-link-content-container {
        padding: 25px 25px;
        background-color: color(background2);
        width: 100%;
        & h6 {   
            margin: 0px;
            color: color(light);
            font-size: size(xl);
            font-weight: weight(bold);
        }
        & img {
            margin-top: 12px;
            height: 20px;
            width: 20px;
            object-fit: contain;
        }
    }
    .article-content-container.first-content-container {
        padding: 25px;
    }
    & .article-content-container {
        padding: 25px;
        // padding: 35px 35px 40px; /* if col is 4:8 */
        background-color: color(main-background);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & h5 {   
            font-size: 26px;
            font-weight: weight(bold);
            margin-bottom: 10px;
            line-height: 1.5;
            color: color(light);
            text-decoration: none;
        }
        & .article-desc {
            font-size: size(regular);
            color: color(white5);
            font-weight: weight(bold);
            margin-bottom: 15px;
            flex: 1;
            margin-top: 5px;
        }
        & .article-tag {
            font-size: size(sm);
            color: #C6A24E;
            font-weight: weight(bold);
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        & .article-date {
            font-size: size(sm);
            // color: rgba(255, 255, 255, 0.2); //change
            color: color(white3);
            font-weight: weight(bold);
        }
    }
}

/* hack for first article */
@media (min-width: 1200px) {
    .article-card-container {
        & .first-image {
            width: 100%;
        }
    }
}

.app-theme-light {
    .article-card-container {
        & .article-link-content-container {
            background-color: color(light-background4);
            & h6 {   
                color: color(dark);
            }
        }
        & .article-content-container {
            background-color: color(light-background4);
            & h5 {   
                color: color(dark);
            }
            & .article-desc {
                color: color(black5);
            }
            & .article-date {
                color: color(black3);
            }
        }
    }
}

#app-locale-ar {
    .article-card-container {
        .article-link-content-container h6 {
            text-align: right;
        }
        .article-link-content-container img {
            margin-left: auto;
            transform: scaleX(-1);
        }
    } 
}