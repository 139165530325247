@import './../../scss_partials/config';

h3 {
    font-weight: weight(bold);
}
.search-bg {
    position: relative;
    padding-bottom: 0px;
    // background-color: color(dark);
    overflow: hidden;
    margin-top: 81px; /* navbar height fixed*/


    & .search-img-absolute-container {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 490px;
        background-color: color(background2);

        & .absolute-searcg-bg {
            position: absolute;
            top: 0px;
            left: 0;
            right: 0;
            bottom: 0;
        }
        & .img-experts-collage-left {
            position: absolute;
            top: 40px;
            left: -375px;
        }
        & .img-experts-collage-right {
            position: absolute;
            top: 40px;
            right: -385px;
        }
    }

    & .search-container {
        min-height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;

        & .search-content-container {
            width: 635px;
            max-width: 650px;
            position: relative;
            text-align: center;

            & .form-control {
                background-color: color(searchinput-bg);
                border: none;
                height: 69px;
                font-size: 16px;
                border-radius: 0px 8px 8px 0;
                color: color(light);
                transition: all ease 300ms;
                &:focus {
                    box-shadow: none;
                }
            }
            & .input-group-prepend {
                background-color: color(searchinput-bg);
                border-radius: 8px;
                transition: all ease 300ms;

                & .input-group-text {
                    background-color: color(searchinput-bg);
                    border-color: color(searchinput-bg);
                    transition: all ease 300ms;
                    border-radius: 8px 0 0 8px;
                    padding: .375rem 5px .375rem 20px; //.375 rem bootstrap default padding
                } 
            }
            & .search-icon {
                height: 17px;
                transition: all ease 300ms;
                color: color(light);
                opacity: 0.7;
            }
            & h2 {
                font-size: size(xxl);
                font-weight: weight(heavy);
                margin-bottom: 20px;
                color: color(light);
                font-family: font(roboto);
                transition: all ease 300ms;
            }
        }
    }
}

.category-section {
    background-color: color(main-background);
    position: relative;
    padding-top: 50px;

    & .category-content-container {
        padding: 0px 15px 250px;

        & .result-not-found {
            color: #909090;
            background-color: #E8E8E8;
            padding: 30px 10px;
            border-radius: 8px;
            text-align: center;
            font-weight: 700;
        }

        & .category-header-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            
            & h3 {
                color: color(light);
                font-family: font(roboto);
                font-weight: weight(bold);
            }
            & .grey-btn {
                background-color: color(background3);
                border: none;
                padding: 10px 15px;
                border-radius: 30px;
                &:hover {
                    transform: scale(1.05);
                }
                & .btn-text {
                    color: #909090;
                    font-size: size(regular);
                    margin: 0 4px;
                    font-weight: 700;
                }
                & .chevron-bottom {
                    width: 10px;
                    margin: 0 4px;
                }
            }
        }
        & .category-cards-container {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px
        }
    }
}
.category-card {
    width: 112px;
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
    cursor: pointer;

    &:hover {
        & .img-container {
            background-color: color(dark);
        }

        & .img-container img {
            filter: grayscale(0);
        }
    }

    & .img-container {
        height: 68px;
        width: 100%;
        border: 2px solid color(background3);
        // background-color: color(category-bg);
        background-color: color(main-background);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
            height: 34px;
            filter: grayscale(1);
        }
    }
    & .title-container {
        display: flex;
        justify-content: center;
        
        
        & .title {
            margin-top: 10px;
            opacity: 0.7;
            font-weight: 700;
            font-size: 14px;
            text-align: center;
            color: color(white7);
        }
    }
}

// session Section Styles

.session-section {
    padding-bottom: 70px;
    padding-top: 0px;
    position: relative;

    & h3 {
        margin-bottom: 40px;
        color: color(light);
    }
    & .category-slider {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    & .session-card-wrapper {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 0;
    }
}

// .searchpage-container {
//     & .expert-card-container {
//         width: 165px;
//         & img {
//             height: 212px;
//         }
//     }
// }
.searchpage-container {
    & .category-section {
        padding-bottom: 230px;
    }
    & .arrows-container {
        & .slick-arrow-prev,
        & .slick-arrow-next {
            top: -76px;
            background-color: color(white10);
        }
        & .slick-arrow-next {
            right: 120px;
        }
        & .slick-arrow-prev {
            right: 180px;
        }
    }
    & .search-session-container {
        & .slick-arrow-next {
            right: 135px;
        }
        & .slick-arrow-prev {
            right: 195px;
        }
    }
    & .category-next-arrow-bg {
        // right: 0px;
        background: linear-gradient(to right, rgba(20, 20, 20, 0), color(main-background), color(main-background), color(main-background) 135%);
        // width: 70px;
    }
    & .category-prev-arrow-bg {
        background: linear-gradient(to left, rgba(20, 20, 20, 0), color(main-background), color(main-background), color(main-background) 135%);
    }
    & .slick-arrow-prev,
    & .slick-arrow-next {
        background: color(main-background);
        top: 10px;
    }
    & .experts-section {
        padding-bottom: 250px;
        padding-top: 50px;
        background-color: color(main-background);
        position: relative;
    
        & h3 {
            margin-bottom: 40px;
            color: color(light);
        }
        .expert-card-parent {
            margin: 0 10px;
        }
    }
    & .expert-card-parent {
        // background-color: red;
        margin: 0px;
        margin-right: 20px;
    }
    & .category-slider {
        margin-top: 30px;
        // background-color: red;
        margin-bottom: 40px;
    }
    & .session-slider {
        margin-right: -15px;
        margin-left: -15px;

        & .session-card-container {
            margin-bottom: 15px;
        }

        & .session-card-wrapper {
            padding-left: 10px;
            padding-right: 10px;
            // margin-bottom: 15px;
        }
    }
}
.category-detail-container,
.search-expert-container {
    & .category-section {
        & .expert-card-parent {
            // background-color: red;
            margin-right: 0px;
        }
        
    }
}
.search-main-container {
    & .category-section {
        padding-bottom: 0px;
    }
}
.category-detail-container {
    & .search-bg .search-container {
        height: 300px;
    }
    .search-content-container h2 {
        margin-top: -80px;
    }
}
.pagination-btn-container {
    // background-color: red;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    & .btn-container {
        & div {
            background-color: color(background3);
            border: none;
            padding: 10px 25px;
            border-radius: 30px;
            color: #909090;
            font-size: 14px;
            margin: 0 4px;
            font-weight: 700;
            // width: 200px;
            width: 120px;
            display: flex;
            justify-content: center;

            & svg {
                margin-left: 5px;
                margin-right: 5px;
                font-size: size(sm);
            }

        }
        &:last-child div {
            margin: 0px 10px;
        }
        &:first-child div {

            & svg {
                // margin-left: 0px;
                // margin-right: 5px;
                margin-top: -1px;
            }
               
        }
    }
}
.search-session-main-container {
    & h3 {
        margin-bottom: 40px;
    }
}
.search-expert-container {
    h3 {
        margin-bottom: 40px;
    }
    & .pagination-btn-container {
        // background-color: red;
        display: flex;
        justify-content: flex-end;

        & .btn-container {
            & div {
                background-color: color(background3);
                border: none;
                padding: 10px 25px;
                border-radius: 30px;
                color: #909090;
                font-size: 14px;
                margin: 0 4px;
                font-weight: 700;
                // width: 200px;
                width: 120px;
                display: flex;
                justify-content: center;

                & svg {
                    margin-left: 5px;
                    margin-right: 5px;
                    font-size: size(sm);
                }

            }
            &:last-child div {
                margin: 0px 10px;
            }
            &:first-child div {

                & svg {
                    // margin-left: 0px;
                    // margin-right: 5px;
                    margin-top: -1px;
                }
                   
            }
        }
    }
}

.arrows-bottom {
    .slick-arrow-prev,
    .slick-arrow-next {
        bottom: -75px;
        &:hover {
            background-color: #959595;
            transition: all ease-in-out 0.5s;
        }
        &:hover .carousal-icon {
            color: #2B2B2B;
            transition: all ease-in-out 0.25s;
            transform: scale(0.75);
        }
    }
    .slick-arrow-prev {
        right: 85px;
    }
    .slick-arrow-next {
        right: 20px;
    }
}
.searchpage-container {
    & .slick-dots {
        bottom: -45px;
    }
}
/****************************************************************************/
                            CATEGORY MODEL
/****************************************************************************/
body.modal-open > :not(.modal) {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    background-color: transparent;
    opacity: 1 !important;
}
body .modal-backdrop {
    background-color: transparent;
    opacity: 1 !important;
    backdrop-filter: blur(20px);
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);
  }
.modal-dialog.custom-modal.modal-dialog-centered {
    height: 80vh;
    width: 90vw;
    max-width: 90vw;
        & .modal-content {
            background-color: rgba(0, 0, 0, 0.85);
            border: 1px solid #303030;
            // height: 75vh;
            width: 90vw;
            border-radius: 16px;
            padding-left: 20px;
            padding-right: 30px;
        }
        & .modal-header {
            border: none;
            display: flex;
            padding-top: 30px;
            padding-left: 0px;
            padding-right: 0px;
            align-items: center;
            
            & .modal-title {
                font-weight: 700;
            }
            & .input-group {
                max-width: 230px;
                margin: 0 !important;
                & .form-control {
                    background-color: #0a0a0a;
                    border-left: none;
                    border-color: #6C6C6C;
                    height: 45px;
                    font-size: 14px;
                    border-radius: 0px 8px 8px 0;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 0.8);
                    padding-left: 8px;
                    &:focus {
                        box-shadow: none;
                    }
                }
                & .input-group-prepend {
                    background-color: #0a0a0a;
                    border-color: #6C6C6C;
    
                    & .input-group-text {
                        background-color: #0a0a0a;
                        border-color: #6C6C6C;
                        border-radius: 8px 0 0 8px;
                        padding: .375rem 3px .375rem 10px; //.375 rem bootstrap default padding
                    } 
                }
                & .search-icon {
                    height: 13px;
                }
            }
            & button.close {
                display: none;
            }
            
        }
        .modal-body {
            padding-left: 0px;
            padding-right: 0px;
            // border-bottom: 1px solid #6C6C6C;
            & .category-cards-container {
                display: flex;
                flex-wrap: wrap;
                margin: -10px;

                & .category-card {
                    margin: 10px 10px;
                }
            }
        }
        .modal-footer {
            border-color: #6c6c6c;
            padding: 20px 0;
            & .btn-container {
                &:hover {
                    transform: none;
                }
            }
        }

  }
.app-theme-light {
    .search-bg {
        position: relative;
        padding-bottom: 0px;
        // background-color: color(dark);
        overflow: hidden;
        margin-top: 81px; /* navbar height fixed*/
    
    
        & .search-img-absolute-container {
            background-color: color(light-background2);
        }
    }
    .pagination-btn-container {
        & .btn-container div {
            background-color: color(light-background3);
            
        }
    }
    & .search-container {
        & .search-content-container {
            & .form-control {
                background-color: color(light-main-background);
                color: color(dark);
            }
            & .input-group-prepend {
                background-color: color(light-main-background);
                color: color(dark);
                & .input-group-text {
                    background-color: color(light-main-background);
                    border-color: color(light-main-background);
                } 
            }
            & .search-icon {
                color: color(dark);
            }
            & h2 {
                color: color(dark);
            }
        }
    }
    & .category-section {
        & h3 {
            color: color(dark);
        }
        background-color: color(light-main-background);
        & .category-content-container {
            & .category-header-row {
                & h3 {
                    color: color(dark);
                }
                & .grey-btn {
                    background-color: transparent;
                    border: 2px solid color(light-background3);
                }
            }
        }
    }
    .category-card {
        & .img-container {
            border: 2px solid color(light-background3);
            background-color: transparent;
            &:hover {
                border-color: #242424;
            }
        }
        & .title-container {
            & .title {
                color: color(black7);
            }
        }
    }
    .search-category-container {
        & .category-card {
            & .img-container {
                // border: 2px solid color(light-background3);
                border: transparent;
                background-color: color(light-background2);
                &:hover {
                    border-color: #242424;
                }
            }
            & .title-container {
                & .title {
                    color: color(black7);
                }
            }
        }
    }
    .session-section {
        & h3 {
            color: color(dark);
        }
    }
    .searchpage-container {
        & .category-next-arrow-bg {
            background: linear-gradient(to right, rgba(255,255,255,0), #ffffff, #ffffff, #ffffff 135%)
        }
        & .category-prev-arrow-bg {
            background: linear-gradient(to left, rgba(255,255,255,0), #ffffff, #ffffff, #ffffff 135%)
        }
        & .experts-section {
            background-color: color(light-main-background);
        
            & h3 {
                color: color(dark);
            }
        }
        & .arrows-container {
            & .slick-arrow-prev,
            & .slick-arrow-next {
                // background-color: color(light-background3);
                background-color: transparent;
                border: 2px solid color(light-background3);

                & svg {
                    color: #909090;
                }
            }
        }
    }
}

#app-locale-ar {
    & .searchpage-container {
        & .expert-card-parent {
            // background-color: red;
            margin-right: 20px;
            margin-left: 0px;
        }
        // & .main-search-expert-slider {
        //     transform: scaleX(-1);
        // }
        & .search-bg .search-container .search-content-container .form-control {
            // background-color: red;
            border-radius: 8px 0px 0px 8px;
        }
        & .search-bg .search-container .search-content-container .input-group-prepend .input-group-text {
            border-radius: 0px 8px 8px 0px;
            padding: .375rem 20px .375rem 5px;
        }
        & .arrows-container .slick-arrow-prev {
            right: auto;
            left: 155px;
        }
        & .arrows-container .slick-arrow-next {
            right: auto;
            left: 215px;
        }
        // & .search-session-container .slick-slider .arrows-container .slick-arrow-prev {
        //     right: auto;
        //     left: 185px;
        //     background-color: red;
        // }
        // & .search-session-container .slick-slider .arrows-container .slick-arrow-next {
        //     right: auto;
        //     left: 225px;
        //     background-color: red;
        // }
        & .search-session-container .slick-slider .slick-arrow-prev {
            right: auto;
            left: 170px;
        }
        & .search-session-container .slick-slider .slick-arrow-next {
            right: auto;
            left: 230px;
        }
    }
    & .search-expert-container h3,
    & .search-session-main-container h3 {
        text-align: right;
    }
}
