@import '../../scss_partials/config';
@import './DownloadPage.scss';

@media (max-width: 575.98px) {
    .download-page-container {
        & .download-platform-image-container {
            height: 250px;
        }
        & .download-platform-image-container .platform-hardware {
            left: -280px;
        }
    }

    #app-locale-ar {

        & .download-page-container {
            & .download-platform-image-container .platform-hardware {
                right: -280px;
            }

        }
    }
}