@import './../../scss_partials/config';

.article-detail-page {
    background-color: #000;
    padding-top: 25px;
    & .social-media {
        border-radius: 8px;
        background-color: #0E0E0E;
        margin-right: 15px;
    }
    & .arrows-bottom .slick-arrow-prev, .arrows-bottom .slick-arrow-next {
        bottom: -60px;
    }
    & .arrows-bottom .slick-dots {
        bottom: -45px;
    }
    & .small-container {
        max-width: 740px;
        padding-top: 50px;
        & .article-header-container {
            & .article-tags-container {
                margin-bottom: 30px;
                & .article-tag {
                    color: color(pinkk);
                    font-size: size(regular);
                    font-weight: weight(bold);
                    text-transform: uppercase;
                }
                & .article-day {
                    color: color(white7);
                    font-weight: weight(bold);
                }
            }
            & .article-title {
                margin-bottom: 30px;
                & h2 {
                    font-size: size(xxxl);
                }
            }
            & .social-container {
                margin-bottom: 30px;
            }
        }
        & .article-img-container {
            width: 100%;
            margin-bottom: 30px;
            & img {
                width: 100%;
                object-fit: cover;
                // max-height: 480px;
                height: 500px;
                margin-bottom: 15px;
            }
            & .img-text {
                color: color(white5);
                font-style: italic;
                font-weight: weight(light);
            }
        }
        & .article-content-container {
            color: color(white7);
            padding-bottom: 200px;
            font-size: size(medium);
            
            & a {
                color: color(golden-yellow);
                
            }
            & strong {
                font-weight: 700;
                color: color(white7);
            }
        
            & p {
                // font-weight: 600;
                margin-bottom: 25px;
            }
        
            & pre {
                color: color(white7);
                margin-bottom: 15px;
            }
            
            & h1,
            & h2,
            & h3 {
                color: color(light);
                margin-bottom: 25px;
        
            }
            & h1 {
                font-size: size(xxxl);
            }
            & h2 {
                font-size: size(xxl);
            }
            & h3 {
                font-size: size(xl);
            }
            & ul,
            & ol {
                margin-bottom: 15px;

                & li {
                    // background-color: red;
                    margin-bottom: 10px;
                }
            }
            
        
            & h4 {
                font-size: size(large);
                font-weight: weight(bold);
                margin-bottom: 15px;
            }
        }
    }
    .article-card-container {
        & img {
            height: 275px;
        }
        & .article-content-container {
            padding: 25px;
            background-color: color(background2);

            & h5 {
                margin: 0px;
                line-height: normal;
                font-size: size(xl);
            }
        }
    }
}
// Article Section Styles

.article-container {
    & h3 {
        margin-bottom: 50px;
    }
}

.app-theme-light {
    .article-container h3 {
        color: color(dark);
    }
    .article-detail-page {
        background-color: #f8f8f8;
        .article-container {
            & h3 {
                color: color(dark);
            }
        }
        & .social-media {
            background-color: color(black10);
        }
        & .small-container {
            & .article-header-container {
                .article-title h2 {
                    color: color(dark);
                }
                & .article-tags-container {
                    & .article-tag {
                        color: color(pinkk);
                    }
                    & .article-day {
                        color: color(black7);
                    }
                }
            }
            & .article-img-container {
                & .img-text {
                    color: color(black5);
                }
            }
            & .article-content-container {
                color: color(black7);
                & a {
                    color: color(golden-yellow);
                }
                & strong {
                    font-weight: 700;
                    color: color(black7);
                }
            
                & pre {
                    color: color(black7);
                }
                
                & h1,
                & h2,
                & h3 {
                    color: color(dark);
            
                }
                
                // & h4 {
                //     font-size: size(large);
                //     font-weight: weight(bold);
                //     margin-bottom: 15px;
                // }
            }
        }
        .article-card-container {
            & .article-content-container {
                background-color: color(light-background2);
            }
        }
    }
}
#app-locale-ar {
    & .article-detail-page {
        & .small-container {
            text-align: right;
        }
        & .related-article-container {
            // background-color:red;
            text-align: right;

            & .slick-slider ,
            .arrows-bottom .article-card-container{
                transform: scaleX(-1);
            }
        }
    }
    .article-container h3 {
        text-align: right;
    }
}