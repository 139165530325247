@import './../../scss_partials/config';

.qwerty {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 81.72px;
    background-color: color(main-background);
}
.nav-container {
    font-size: size(regular);
    // background-color: color(main-background);
    background-color: color(nav-background);
    position: fixed;
    top: 0;
    backdrop-filter: blur(25px);
    z-index: 1000;
    padding-left: 30px;
    padding-right: 30px;
    transition: all ease 300ms;
    .country-dropdown {
        // background-color: red;

        & .search-input-container {
            position: relative;

            & .search-icon-container {
                position: absolute;
                top: 0;
                left: 0;
                flex: 1;
                right: 0px;
                bottom: 0px;
                display: flex;
                align-items: center;
                pointer-events: none;
                padding-left: 25px;
                padding-right: 25px;
                
                & svg {
                    font-size: 16px;
                    margin-top: -3px;
                    color: color(white3);
                }
            }
        }

        & .dropdown-menu {
            left: auto;
            right: 0;
            padding: 0px;
            background-color: #141414;
            overflow: hidden;
            width: 350px;
            height: 400px;
            overflow-y: scroll;
            padding: 10px 0px;
            padding-top: 0px;
            border-radius: 10px;
            border: 2px solid color(white10);
            -webkit-box-shadow: -10px 10px 20px 0 rgba(0,0,0,0.2);
            box-shadow: -10px 10px 20px 0 rgba(0,0,0,0.2);

            & input {
                height: 60px;
                width: 100%;
                background: transparent;
                border: none;
                border-bottom: 2px solid color(white10);
                color: #fff;
                padding-left: 55px;
                font-size: 14px;
                font-weight: 500;

                &:focus {
                    border-color: color(white10);
                    outline-color: transparent;
                    box-shadow: none;
                    outline: none;
                }
            }
            
            & a {
                padding: 0px;
                padding: 15px 25px;
                transition: all ease 200ms;
                position: relative;
                display: flex;
                align-items: center;
                color: color(light);
                font-size: 14px;
                font-weight: 500;

                
                & img {
                    height: 25px;
                    width: 25px;
                    border-radius: 30px;
                    margin-right: 10px;
                    border: 1px solid color(golden-yellow);
                }
                &::before{
                    content: '';
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    -moz-border-radius: 7.5px;
                    -webkit-border-radius: 7.5px;
                    border-radius: 7.5px;
                    background-color: color(golden-yellow);
                    position: absolute;
                    left: -10px;
                    transition: all ease 300ms;
                }
                &::after{
                    content: '';
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    -moz-border-radius: 7.5px;
                    -webkit-border-radius: 7.5px;
                    border-radius: 7.5px;
                    background-color: transparent;
                    position: absolute;
                    right: 25px;
                    transition: all ease 300ms;
                }
                &:hover {
                    padding-left: 35px;
                    background-color: transparent;
                    transition: all ease 200ms;
                    
                    &::before {
                        left: -5px;
                    }
                    &::after{
                        right: 25px;
                        background-color:color(golden-yellow);
                    }
                }
            }
        }

        & .dropdown-toggle {
            & img {
                height: 15px;
            }
        }

        & .dropdown-toggle::after{
            display: none; 
        }

        & .btn-primary {
            background-color: transparent;
            border: none;
            padding: 8px 15px;

            &:active {
                background-color: transparent;
                box-shadow: none;
            }
            &:focus {
                box-shadow: none;
            }
            &:active:focus {
                box-shadow: none;
            }
        }
    }

    & .justify-content-center {
        flex: 1
    }
    & .navbar.custom-navbar {
        & .navbar-toggler {
            padding: 6px 12px;
        }
        background-color: transparent;
        padding: 20px 0px;

        & .MuiSelect-icon {
            display: none;
        }
        & .MuiInput-underline:before {
            border: none;
        }
        & .MuiInput-underline.Mui-focused:after {
            transform: scaleX(0);
        }
        & .MuiSelect-select:focus {
            background-color:transparent;
        }
        & .MuiSelect-select.MuiSelect-select {
            padding-right: 15px;
            padding-left: 15px;
            color: color(white5);
            font-weight: 600;
        }

        & .logo {
            height: 30px;
        }
        & .logo-light {
            height: 30px;
            display: none;
        }

        & .nav-link {
            padding-left: 20px;
            padding-right: 20px;
            color: color(white5);
            font-weight: 500;
            font-size: size(medium);
            transition: all ease 300ms;
        }
        & .nav-link.active {
            color: color(light);
            font-weight: weight(bold);
        }

        & .nav-link.nav-btn {
            padding-left: 15px;
            padding-right: 15px;
            color: #a1a1a1;

            & img {
                height: 15px;
            }
        }
    }
}
// .country-modal{
//     & .MuiDialogContent-root{
//         // & .country-col {
//         //     &:first-child {
//         //         // background-color: blue;
//         //         padding-left: 0px;
//         //         padding-right: 5px;
//         //     }
//         //     &:last-child {
//         //         // background-color: green;
//         //         padding-left: 5px;
//         //         padding-right: 0px;
//         //     }
//         // }
//         & .country-item-container {
//             display: flex;
//             align-items: center;
//             padding: 15px;
//             padding-left: 0px;
//             cursor: pointer;
//             border-radius: 5px;
//             transition: all ease 200ms;

//             &:hover {
//                 background-color: color(background3);
//                 padding-left: 25px;
//             }

//             & img {
//                 height: 25px;
//                 width: 25px;
//                 border-radius: 25px;
//                 background-color: color(background3);
//                 margin-right: 10px;
//             }

//             & label {
//                 margin: 0px;
//                 flex: 1;
//                 cursor: pointer;
//             }
//         }
//     }
//     & .search-input-container {
//         position: relative;
//         margin-bottom: 15px;
//         display: flex;
//         align-items: center;
//         & input {
//             width: 100%;
//             height: 56px;
//             border: none;
//             box-shadow: none;
//             background-color: color(main-background);
//             padding-left: 50px;
//             border-radius: 5px;
//             font-size: size(regular);
//             font-weight: weight(medium);
//             color: color(light);

//             &:focus {
//                 outline: none;
//             }
//         }
//         & .search-icon-container {
//             position: absolute;
//             margin-left: 20px;
//             color: color(white5);

//         }
//     }
//     & .MuiDialog-paperWidthSm {
//         max-width: 450px;
//         height: 520px;
//     }
// }
.app-theme-light {
    & .qwerty {
        background-color: color(light-background2);
    }
    & .nav-container {
        background-color: color(light-nav-background);

        & .navbar.custom-navbar {
            & .navbar-toggler {
                color: color(black3);
                border-color: color(black10);
            }
            & .nav-link {
                color: color(black5);
            }
            & .nav-link.active {
                color: color(dark);
                font-weight: weight(bold);
            }
            & .logo-light {
                display: inline-block;
            }
            & .logo {
                display: none;
            }
        }
    }
    & .nav-container .navbar.custom-navbar .MuiSelect-select.MuiSelect-select {
        color: color(black5);
    }
    & .nav-container .country-dropdown .dropdown-menu {
        background-color: #fff;
        -webkit-box-shadow: -10px 10px 20px 0 rgba(0,0,0,0.1);
        box-shadow: -10px 10px 20px 0 rgba(0,0,0,0.1);
        border-color: #ededed;

        & input {
            background-color: red;
            background-color: color(light-main-background);
            color: color(dark);
            border-bottom-color: #ededed;
        }
        & a {
            color: color(dark);
        }
        & .search-input-container .search-icon-container svg {
            // color: color(black10);
            color: rgba(0,0,0,0.4)
        }
    }
    // .nav-container .country-dropdown .dropdown-menu input
}

#app-locale-ar {
    & .nav-container .country-dropdown {
        & .dropdown-menu {
            right: auto;
            left: 0;
            & input {
                padding-right: 55px;
            }
            
            & a {
                & img {
                    margin-left: 10px;
                    margin-right: 0px;
                }
            }
        }
    }
}