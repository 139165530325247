@import './../../scss_partials/config';

.session-card-container {
    &:hover .hover-overlay {
        background-color: color(pinkk);
        mix-blend-mode: screen;
    }
    & .card-img-wrapper {
        position: relative;
        isolation: isolate;
        height: 100%;
        width: 100%;
        overflow: hidden;
        border-radius: 15px;
        & .hover-overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .session-card-overlay-effect {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
    }
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 20px;

    & img {
        width: 100%;
        height: 220px;
        object-fit: cover;
    }
    & .session-card-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: flex-end;
        padding: 15px;
        flex-direction: column;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
        background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
    }
    & h4 {
        font-size: size(xl);
        color: #fff;
        font-weight: weight(heavy);
        margin: 0px;
    }
}

#app-locale-ar {
    .session-card-container h4 {
        text-align: right;
    }
}