@import './../../scss_partials/config';

.video-card-container {
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    // cursor: url('./../../assets/images/rounded-cursor-play.png'), auto;
    cursor: pointer;
    &:hover .hover-overlay {
        background-color: color(pinkk);
        mix-blend-mode: screen;
    }
    & .card-img-wrapper {
        position: relative;
        isolation: isolate;
        height: 100%;
        width: 100%;
        overflow: hidden;
        border-radius: 15px;
        & .hover-overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    & img {
        height: 380px;
        width: 100%;
        object-fit: cover;
    }
    & .video-card-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        padding: 15px;
        flex-direction: column;
        // background-color: red;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
        background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
    }
    & .video-card-content-container {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        & img {
            height: auto;
            width: 50px;
        }
        & h6 {
            margin: 0px;
        }
    }
}
.video-card-container:hover .video-card-content-container img {
    // display:none;
}

@media (max-width: 575.98px) {
    .video-card-container img {
        height: 200px;
    }
}
// #app-locale-ar {
//     & .video-card-container {
//         .video-card-content-container {
//             flex-direction: row-reverse;
//         }
//     }
// }