@import '../../scss_partials/config';
@import './Page404.scss';

@media (max-width: 575.98px) {
    .not-found-container {
        padding-left: 0px;
        padding-right: 0px;
    }
    .not-found-container .four-not-four-container {
        & img {
            height: auto;
            width: 100%;
        }
        & .four-not-four-illustration img {
            margin-top: 20px;
        }
    }
}