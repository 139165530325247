@import './ContentLoader.scss';

@media (max-width: 575.98px) {
    .home-banner-placeholder {

        & .home-banner-loader-container {
            display: inline-flex;
            height: 185px;
            overflow-x: hidden;
        }
    
        & .home-banner-loader-slide {
            height: 100%; 
            width: 90vw !important;
            border-radius: 10px;
    
            &:first-child {
                margin-right: 15px;
            }
        }
    }

    .category-filter-placeholder {
        padding: 0px;
        margin-left: -15px;
        margin-right: -15px;
    }
    .featured-session-loader {
        flex-direction: column; 
        margin-top: -50px;

        &:last-child {
            margin-top: 15px !important;
            background-color: yellow;
            display: none;
        }

        & .session-loader-card {
            margin-bottom: 15px;

            &:nth-child(2) {
                display: none;
            }
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
    .featured-article-loader-container {
        padding-left: 0px;
        overflow-x: hidden;
        padding: 0px;
        & .featured-article-loader {
            & .article-loader-card {
                max-width: 90%;
                &:first-child {
                    margin: 0px;
                }
            }
        }
    }
    .video-channel-filter-tab-loader {
        min-width: 135px;
    }
}