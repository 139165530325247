@import './scss_partials/config';
// @import "~bootstrap/scss/bootstrap";

body {
    background-color: color(main-background);
    color: #fff !important;
    font-family: font(roboto);
    // transition: all ease .3s;
    margin-top: 81px;
    overflow-x: hidden;
    -webkit-tap-highlight-color: unset;
}
.title-primary, h1, h2 {
    font-family: font(raleway);
}
.ar-font {
    font-family: 'Cairo', sans-serif;
}
#app-locale-ar {
    & .title-primary, h1, h2 {
        font-family: 'Cairo', sans-serif;
    }
}
body {
    &.app-theme-light {
        background-color: color(light-main-background);
    }
    &#app-locale-ar {
        direction: rtl;
        font-family: 'Cairo', sans-serif;
    }
    overflow-x: hidden;
}

// #app-locale-ar {
//     & body {
//     }
// }
.download-modal {
    & .MuiBackdrop-root {
        background-color: color(black5);
        backdrop-filter: blur(10px);
    }
    & .MuiDialogContent-root {
        background-color: color(golden-yellow);
        color: color(dark);
        background-image: url('./assets/images/download-modal-bg.png');
        background-repeat: no-repeat;
        // background-size: cover;
        background-size: 110%;
        background-position: right -235px top -175px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 50px;
        color: #2d2d2d;

        & .close-btn-container {
            cursor: pointer;
            height: 40px;
            width: 40px;
            border-radius: 5px;
            // background-color: red;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;

            &:hover {
                background-color: color(white3);
            }
        }
        & h2 {
            margin: 0px;
            font-weight: weight(heavy);
            font-size: size(xxxl);
        }

        & p {
            margin: 20px 0px;
            margin-top: 15px;
            font-size: size(medium);
            font-weight: 500;
            color: color(black7);
        }
    }
    & .MuiPaper-root {
        min-height: 600px;
        // max-height: calc(100vh - 100px);;
        min-width: 800px;
    }
}
@media (max-width: 575.98px) {
    .slick-dots {
        display: none !important;
    }
    .download-modal {
        & .MuiPaper-root {
            min-height: 70vh;
            min-width: 90%;
        }
        & .MuiDialogContent-root {
            padding: 25px;
            background-position: right -60px top -180px;
        }
    }
    // .download-modal .MuiDialogContent-root
}