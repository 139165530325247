@import '../../scss_partials/config';
@import './footer.scss';

@media (max-width: 575.98px) {
    .footer-bg {
        & .logo-row {
            flex-direction: column;
            align-items: flex-start;
        }
        & .social-media-container {
            margin-top: 15px;
        }
        & .footer-block-container {
            // margin-bottom: 25px;
            margin-bottom: 25px;
            
            &:last-child {
                margin-bottom: 0px;
            }
        }
        & div a h4 {
            // background-color: red;
            margin-bottom: 15px;
        }
    }
}