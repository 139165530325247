@import './../../scss_partials/config';
@import './Articles.scss';

@media (max-width: 575.98px) {
    .article-body {
        // background-color: red;

        & .article-card-container {
            & .article-content-container {
                padding: 15px;
                &.first-content-container {
                    padding: 15px;
                }
            }
            & img {
                height: 200px;
            }
        }
        & .article-card-container {
            margin-bottom: 15px;
        }
    }
}