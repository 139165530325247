@import '../../scss_partials/config';
@import './ForExperts.scss';


@media (max-width: 575.98px) {
    .for-experts-container {
        & .header-banner-container .card-wrapper {
            display: none;
        }
        & .features-section .features-row .features-list,
        & .features-section .features-row .features-list:last-child {
            justify-content: center;

            & ul li {
                flex-direction: column-reverse;

                & span {
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 10px;
                }
            }
        }
        & .features-section .features-row .features-mob-container img {
            margin-bottom: 50px;
        }
        // .for-experts-container .features-section .features-row .features-list:last-child
        & .register-benefits-section .benefits-col-container {
            min-width: 100%;
            margin-bottom: 50px;
            
            &:last-child {
                // background-color: red;
                margin-bottom: 0px;
            }
        }
    }

}

@media (max-width: 575.98px) {

    #app-locale-ar {
        & .for-experts-container  {
            & .features-section .features-row .features-list ul li span {
                margin-right: 0px;
            }
            & .features-section .features-row .features-list:last-child ul li span {
                margin-left: 0px;
            }
        }
    }
}
