@import './../../scss_partials/config';


.btn-container {
    display: inline-block;
    color: #fff;
    font-weight: weight(medium);
    transition: all ease 300ms;
    // transform-origin: top left;
    &:hover {
        color: color(light);
        text-decoration: none;
        transform: scale(1.1);
    }
    & div {
        background-color: #000;
        height: 50px;
        padding: 0px 60px;
        display: flex;
        align-items: center;
        border: 1px solid #F4C85E;
        border-radius: 5px;
    }
}


.app-theme-light {
    .btn-container {
        color: #000;
        &:hover {
            color: color(dark);
        }
        & div {
            background-color: color(light-background2);
            border-width: 2px;
        }
    }
    
}