@import './../../scss_partials/config';
@import './search.scss';

@media (max-width: 575.98px) {
    .searchpage-container {

        & .category-section .category-content-container {
            padding-left: 0px;
            padding-right: 0px;
        }

        & .category-section {
            padding-left: 0px;
            padding-right: 0px;
        }

        & .category-next-arrow-bg,
        & .category-prev-arrow-bg {
            display: none;
        }

        // & .search-session-main-container {

        // }

        & .main-search-category-container {
            padding-left: 15px;
            padding-right: 15px;
            border-bottom: 2px solid color(white10);
            padding-bottom: 90px;

            & .category-container {
                padding-left: 0px;
                padding-right: 0px;

                & .category-card {
                    margin-left: 0px;
                    margin-right: 15px;
                }
            }

            & .slick-arrow-next,
            & .slick-arrow-prev {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: color(white10);
                top: auto;
                bottom: -50px;
                left: auto;
                right: 0;
            }

            & .slick-arrow-prev {
                right: 60px;
            }

            & .category-card .title-container .title {
                overflow-y: hidden;
            }

            & .slick-slider {
                margin-bottom: 0px;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
        & .main-search-expert-container,
        & .search-session-container {
            border-bottom: 2px solid color(white10);
            padding-top: 25px;
            padding-bottom: 100px;
            padding-left: 15px;
            padding-right: 15px;
            & .slick-arrow-prev,
            & .slick-arrow-next {
                top: auto;
                bottom: -60px;
                right: 0;
            }
            & .slick-arrow-prev {
                right: 60px;
            }
        }
        & .search-session-container {
            & .slick-arrow-prev,
            & .slick-arrow-next {
                right: 15px;
            }
            & .slick-arrow-prev {
                right: 75px;
            }
        }
    }
    .search-category-container {
        & .category-card {
            width: 100%;
            margin: 0px;
            margin-bottom: 15px;
        }
        & .category-card-col-container {
            padding: 0px 10px;
        }
        & .category-section {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .category-detail-container,
    .search-expert-container {
        & .category-section {
            padding-left: 10px;
            padding-right: 10px;
            & .expert-card-parent {
                padding: 0px 10px;
                & .expert-card-container  {
                    // background-color: red;
                    width: 100%;
                }
            }
            
        }
    }
}