@import '../../scss_partials/config';
.detail-page {    /* resuable component */
    background-color: color(background4);

    & .detail-bg {
        background: url(./../../assets/images/detail-bg.png) no-repeat 0% 0%;
        background-size: cover;
        height: 330px;
        background-color: color(background2);

        & .grey-btn {
            background-color: rgba(255, 255, 255, 0.1);
            border: none;
            padding: 5px 10px;
            border-radius: 20px;
            position: absolute;
            top: 70px;
            left: 15px;
            height: 42px;
            width: 95px;
            color: #909090;
            &:hover {
                transform: scale(1.05);
            }
            & .btn-text {
                color: #909090;
                font-size: 14px;
                margin: 0 6px;
                font-weight: 700;
            }
            & .chevron-left {
                width: unset;
                height: 14px;
                margin: 0 6px;
            }
        }
    }
    & .detail-container {
        min-height: 300px;
        margin-top: -150px;
        padding-bottom: 200px;
    
        // & .profile-col {
        //     min-height: 200px;
        //     background-color: rgba(0, 0, 0, 0);
        //     border-radius: 15px;
        //     display: flex;
        //     flex-wrap: wrap;
        //     overflow: hidden;
        //     & h1 {
        //         word-break: break-all;
        //     }
        // }
        & .content-col {
            // min-height: 200px;
            background-color: color(main-background);
            border-radius: 15px;
            padding: 50px;
            margin-bottom: 50px;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.08);
            -webkit-box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.08);
            -moz-box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.08);
        }
        & .left-col-container {
            padding-right: 10px;
            & .social-media {
                color: #C6C6CB;
                background-color: #1c1c1c;
                &:hover {
                    transform: scale(1.2) rotate(-360deg);
                    transition: all ease .3s;
                    background-color: #C6C6CB;
                    color: #1c1c1c;
                }
            }
        }
        & .right-col-container {
            padding-left: 10px;

            & .category-slider {
                margin-top: 0px;
            }
        }
    }
    & .session-card-container {
        margin-bottom: 0;
        margin-top: 20px;
    } 
}

/**********************************  expert details page   **************************/
.expert-detail-page {
    // background-color: #0e0e0e;
    background-color: color(background2);

    & .region-block-bar {
        text-align: center;
        background-color: color(pinkk);
        padding: 7px 0px;
        font-size: size(regular);
        font-weight: 500;

        & svg {
            margin: 0px 7px;
            font-size: 14px;
        }
    }
    
    & .session-card-row {
        margin: 0 -10px;
        & .session-card-wrapper {
            padding: 0 10px;
        }
    }

    & .img-expert-container {
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        margin-bottom: 20px;
        // height: 150px;

        & img {
            width: 100%;
        }
        & .dark-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            padding: 15px;
            flex-direction: column;
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.8981967787114846) 100%);
        }
        & .expert-name {
            margin-bottom: 0px;
            font-size: size(xxl);
            font-weight: 700;
        }
        & .expert-category {
            color: rgba(255, 255, 255, 0.6);
            font-size: 14px;
            font-weight: 600;
        }
    }
    & .language-tag-container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;

        & .language-tag {
            margin-bottom: 10px;
        } 
    }
    & .dashboard-container {
        margin-bottom: 25px;
        display: flex;
        background-color: color(main-background);
        border: 1px solid color(background3);
        border-radius: 8px;
        justify-content: space-between;
        padding: 15px 15px;
        text-align: center;

        & .dashboard-item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            & .dash-number {
                font-size: size(xxl);
                font-weight: weight(extra-bold);
                margin-bottom: 13px;
                text-align: center;
                color: color(light);
            }
            & .dash-category {
                font-size: size(sm);
                color: color(white5);
                font-weight: 600;
                text-transform: capitalize;
            }
        }
    }
    & .social-container {
        margin-bottom: 20px;
        & h5 {
            font-weight: 700;
            color: color(light);
            margin-bottom: 10px;
        }
        & .social-icon-container {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -10px;

            & .social-media-container {
                margin-top: 0px;
                margin-left: 10px;
            }

            & .social-icon {
                height: 40px;
                margin: 5px 7px;
            }
        }
    }
    & .content-header {
        font-size: size(xxl);
        // margin-top: 20px;
        font-weight: 700;
        margin-bottom: 15px;
        color: color(light);
    }
    & .expert-desc {
        color: color(white7);
        font-size: size(regular);
        line-height: 1.9;
        font-weight: 600;
        margin-bottom: 40px;
    }
}
.app-theme-light {
    & .detail-page {
        & .left-col-container h4 {
            color: color(dark);
        }
        background-color: color(light-main-background);
        & .detail-bg {
            // background: color(light-background3);
            background-image: url('../../assets/images/detail-bg-light.svg');
            background-color: color(light-background2);
            background-position: 100% 100%;
        }
        & .detail-container .content-col {
            background-color: color(light-main-background);
            // box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.08);
            // -webkit-box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.08);
            // -moz-box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.08);

        }
        .detail-bg .grey-btn {
            background-color: transparent;
            border: 2px solid #464646;
        }
        & .detail-bg .grey-btn .btn-text {
            color: rgba(0,0,0,0.6);
        }
        & .detail-container {
            & .left-col-container {
                padding-right: 10px;
                & .social-media {
                    background-color: color(light-background2);
                    color: #252525;
                    &:hover {
                        color: #252525;
                        transform: scale(1.2) rotate(-360deg);
                        transition: all ease .3s;
                        background-color: #252525;
                        color: #e8e8e8;
                    }
                }
            }
        }
    }
    & .expert-detail-page {
        & .expert-desc {
            color: color(black7);
        }
        & .content-header {
            color: color(dark);
        }
        & .dashboard-container {
            background-color: color(light-background2);
            border: 1px solid color(light-background3);
        }
        & .dashboard-container .dashboard-item .dash-number {
            color: color(dark);
        }
        & .dashboard-container .dashboard-item .dash-category {
            color: color(black5);
        }
        & .social-container h5 {
            color: color(dark);
        }
        & .img-expert-container {
            & .expert-name {
                color: #fff;
            }
        }
    }
}
#app-locale-ar {
    .detail-page {
        & .detail-container {
            text-align: right;
        }
        & .content-col .category-slider,
        & .content-col .category-slider .category-card {
            transform: scaleX(-1);
        }
        & .detail-bg .grey-btn {
            left: auto;
            right: 15px;
        }
        & .detail-bg .grey-btn .chevron-left {
            transform: scaleX(-1);
        }
    }
}