//for dark mode primary naming convention
$colors: (
    "light": #fff,
    "dark": #000,
    "black7": rgba(0,0,0,0.7),
    "black5": rgba(0,0,0,0.5),
    "white7": rgba(255,255,255,0.7),
    "white5": rgba(255,255,255,0.5),
    "white10": rgba(255,255,255,0.1),
    "black10": rgba(0,0,0,0.1),
    "white3": rgba(255,255,255,0.3),
    "black3": rgba(0,0,0,0.3),
    // "main-background": #141414,
    "main-background": #101010,
    "light-main-background": #ffffff,
    "background2": #000000,
    // "background2": #0E0E0E,
    "light-background2": #e8e8e8,
    "background3": #242424,
    "searchinput-bg": #343434,
    "light-background3": #e2e2e2,
    "background4": #0e0e0e,
    "light-background4": #F7F7F7,
    "nav-background": rgba(16,16,16,0.8),
    "light-nav-background": rgba(232,232,232,0.6),
    "pinkk": #F4725C,
    "golden-yellow": #F4C85E,
    "olive-green": #9EC2BB,
    "navy-blue": #475869,
    "category-bg": #141414,
    "light-category-bg": #e8e8e8,
    "dots-active": #828282,
    "light-dots-active": #989898,
);
// for light mode 
// $colors: (
//     "light": #000,
//     "dark": #fff,
//     "dark2": #242424,
//     "black7": rgba(255,255,255,0.7),
//     "black5": rgba(255,255,255,0.5),
//     "white7": rgba(0,0,0,0.7),
//     "white5": rgba(0,0,0,0.5),
//     "white10": rgba(0,0,0,0.1),
//     "white5": rgba(0,0,0,0.5),
//     "white3": rgba(0,0,0,0.3),
//     //"main-background": #F3F3F3,
//     "main-background": #FDFDFD,
//     "light-background2": #f2f2f2,
//     // "background2": #e8e8e8,
//     "background3": #E8E8E8,
//     "background4": #F7F7F7,
//     "nav-background": rgba(253,253,253,0.8),
//     "pinkk": #F4725C,
//     "golden-yellow": #F4C85E,
//     "olive-green": #9EC2BB,
//     "navy-blue": #475869,
//     "category-bg": #e8e8e8,
//     "dots-active": #989898,
// );
@function color($color-name) {
    @return map-get($colors, $color-name);
}
$font-sizes: (
    "xs": 10px,
    "sm": 12px,
    "regular": 14px,
    "medium": 16px,
    "large": 18px,
    "xl": 22px,
    "xxl": 28px,
    "xxxl": 36px,
    "xxxxl": 50px,
);
@function size($size-name) {
    @return map-get($font-sizes, $size-name);
}
$font-weights: (
    "light": 300,
    "regular": 400,
    "medium": 500,
    "bold": 700,
    "heavy": 800,
    "extra-bold": 900,
);
@function weight($weight-name) {
    @return map-get($font-weights, $weight-name);
}

$font-family: (
    "raleway": ('Raleway', sans-serif),
    "roboto": ('Roboto', sans-serif),
);
@function font($font-name) {
    @return map-get($font-family, $font-name);
}
