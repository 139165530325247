@import './../../scss_partials/config';

.article-body {
    background-color: color(background2);
    padding: 50px 0 0px;

    & .pagination-btn-container {
        display:flex;
        justify-content: space-between;
    }
    & .pagination-flex-start {
        justify-content: flex-start;
    }
    & .pagination-flex-end {
        justify-content: flex-end;
        width: 100%;
    }
    & .article-card-container {
        margin-bottom: 30px;
    }
    & .pagination-absolute-container {
        bottom: -50px;
        position: absolute;
        height: 40px;
        left: 20px;
        right: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .pagination-dots-container {
            display: flex;
            height: 100%;
            align-items: center;
            & .custom-dots {
                height: 15px;
                width: 15px;
                background-color: color(background3);
                border-radius: 50%;
                margin: 0 3px;
            }
            & .custom-dots.active {
                background-color: color(dots-active);
            }
        }
        & .pagination-arrows-container {
            position: relative;
            height: 100%;
            min-width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            & .slick-arrow-prev {
                margin: 0 5px;
                display: flex;
                position: relative;
            }
            & .slick-arrow-next {
                margin: 0 5px;
                display: flex;
                position: relative;
            }
        }
    }
    & .download-wrapper {
        padding-top: 0px;
        padding-bottom: 250px;
        background-color: color(main-background);
        position: relative;
    }
}
.app-theme-light {
    & .article-page {
        & .header-container {
            background-color: color(light-background2);
            background-image: url('./../../assets/images/article-bg-light.png') !important;
        }
    }
    .article-body {
        background-color: color(light-main-background);
        & .pagination-absolute-container {
            & .pagination-dots-container {
                & .custom-dots {
                    background-color: color(light-background3);
                }
                & .custom-dots.active {
                    background-color: color(light-dots-active);
                }
            }
        }
        & .download-wrapper {
            background-color: color(light-background2);
            padding-bottom: 165px;
        }
    }
}

#app-locale-ar {
    & .article-body {
        text-align: right;
    }
}