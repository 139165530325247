@import '../../scss_partials/config';

.home-banner-placeholder {
    overflow: hidden;
    & .home-banner-loader-container {
        display: inline-flex;
        height: 412px;
        // width: 100%;
    }

    & .home-banner-loader-slide {
        height: 100%; 
        width: 50vw; 
        border-radius: 10px;

        &:first-child {
            margin-right: 15px;
        }
    }

}
.featured-session-loader {
    display: flex;
    flex-direction: row; 

    & .session-loader-card {
        flex: 1;
        height: 220px; 
        margin: 0px 10px;
    }
}
.featured-article-loader {
    display: inline-flex;
    height: 456px;
    width: 100%;
    margin-bottom: 37px;

    & .article-loader-card {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        margin: 0px 15px;

        &:first-child {
            margin-left: 0px;
        }
        &:last-child {
            margin-right: 0px;
        }
        // marginLeft: index === 0 ? 0 : '15px';
        // margin-right: index === 2 ? 0 : '15px';
    }
}
.video-channel-filter-tab-loader {
    width: 135px;
    height: 44px;
    margin: 0px 10px;
    border-radius: 12px;

    &:first-child {
        margin-left: 0px;
    }
}