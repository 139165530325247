@import '../../scss_partials/config';
.footer-bg {
    min-height: 100px;
    background-color: color(background2);
    padding: 2px 0 60px;
    position: relative;

    & .logo-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 42px;
        margin-bottom: 42px;
        border-bottom: 2px solid #191919;

        & .footer-logo {
            height: 40px;
        }
        & .footer-logo-light {
            height: 40px;
            display: none;
        }
        & .social-container {
            display: flex;
            flex-wrap: wrap;

            & .social-icon {
                height: 40px;
                margin: 0 5px;
            }
        }
    }
    & div a h4 {
        color: color(white7);
        font-size: size(medium);
        font-weight: weight(medium);
    }
    & div h4 {
        font-size: size(large);
        font-weight: weight(heavy);
        margin-bottom: 25px;
        // opacity: 0.7;
        color: color(light);

        // &:first-child {
        //     opacity: 1;
        // }
    }
    & .btn-container {
        & div {
            background-color: #000000;
            font-size: size(medium);
            font-weight: weight(bold);
        }
    }
}

.home-page {
    & .platform-download-container {
        display: flex;
        & .download-platform {
            background-color: transparent;
    
        }
    }
}
.platform-download-container {
    display: flex;
    & .download-platform {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: color(background2);
        color: color(golden-yellow);
        font-size: 25px;
        border-radius: 10px;
        cursor: pointer;
        transition: all ease 300ms;
        position: relative;
        border: 1px solid color(golden-yellow);

        & .platform-icon {
            // position: relative;
            transition: all ease 300ms;
        }

        &:hover {
            width: 100px;
            // display: flex;
            // justify-content: flex-start;

            & .download-arrow {
                opacity: 1;
            }

            & .platform-icon {
                margin-left: -50px;
            }
        }

        // &:first-child {
        // }
        
        &:nth-child(2) {
            font-size: 30px;
            margin-right: 10px;
            margin-left: 10px;
        }

        & .download-arrow {
            font-size: 15px;
            position: absolute;
            right: 15px;
            // color: red;
            opacity: 0;
            transition: all ease 300ms;
        }
    }
    & .contact-action {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
        text-decoration: none;
        border: 2px solid #242424;
        background-color: transparent;
        color: #242424;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease 300ms;
        position: relative;
        border-radius: 10px;


        &:hover {
            width: auto;
            // display: flex;
            // justify-content: flex-start;

            & .download-arrow {
                opacity: 1;
            }

            & .platform-icon {
                margin-left: 0px;
                // font-size: 2px;
            }
        }
        
        & .platform-icon {
            font-size: 16px;
        }
        & .download-arrow {
            position: static;
            right: auto;
            // color: red;
            opacity: 1;
            transition: all ease 300ms;
            font-size: 12px;
            margin-left: 10px;
        }
    }
    & .download-platform:hover ~ .contact-action .download-arrow {
        margin-left: -10px;
        opacity: 0;
    }

}

.app-theme-light {
    .download-modal {
        & .MuiBackdrop-root {
            background-color: color(white5);
        }
    }
    & .footer-bg {
        background-color: color(light-background2);

        & .logo-row {
            border-color: color(light-background3);
            & .footer-logo {
                display: none;
            }
            & .footer-logo-light {
                display: inline-block;
            }
        }
        & .btn-container {
            & div {
                background-color: color(light-background2);
                color: color(dark);
                border-width: 2px;
            }
        }
        & div a h4 {
            color: color(black7);
        }
        & div h4 {
            color: color(dark);
        }
    }
    .download-modal {
        & .MuiBackdrop-root {
            background-color: color(black5);
            backdrop-filter: blur(10px);
        }
        & .MuiDialogContent-root {
            background-color: color(dark);
            color: color(light);

            & p {
                color: color(white7);
            }
        }
    }
    & .contact-action {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
        text-decoration: none;
        border: 2px solid #242424;
        background-color: #242424;
        color: color(golden-yellow);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease 300ms;
        position: relative;
        border-radius: 10px;
    }
    // & .platform-download-container {
    //     display: flex;
    //     & .download-platform {
    //         // background-color: color(main-background);
    //         background-color: color(background2);
    //     }
    // }
}
.app-theme-light {
    & .home-page {
        & .platform-download-container {
            & .download-platform {
                background-color: color(background2);
            }
        }
    }
}

#app-locale-ar {
    .download-modal {
        & .MuiDialogContent-root {
            background-image: url('./../../assets/images/download-modal-bg-rtl.png');
            background-position: right 150px top -175px;
            text-align: right;
        }
    }
    .footer-block-container {
        text-align: right;
    }
    .platform-download-container .contact-action .download-arrow {
        margin-left: 0px;
        margin-right: 10px;
    }
    & .download-platform:hover ~ .contact-action .download-arrow {
        margin-right: -10px;
        opacity: 0;
    }
}