.wave-container {
    margin: -50px 0 0;
    position: relative;
    height: 150px;
    background-color: transparent;

    & .custom-wave-under {
        position: absolute;
        top: 0; 
        z-index: 2;
    }
    & .custom-wave-middle {
        position: absolute;
        top: 0; 
        z-index: 3;
    }
    & .custom-wave-over {
        position: absolute;
        top: 30px;
        z-index: 4;
    }
}
.wave-container-absolute {
    position: absolute;
    // top: -130px;
    left: 0;
    right: 0;
    height: 150px;
    direction: ltr;
}