@import './../../scss_partials/config';

.highlited-title {
    // margin: 70px 0px;
    // color: color(light);
    // font-weight: weight(bold);
    font-size: 54px;
    // margin: 40px 0 0px 0;
    color: color(light);
    font-weight: weight(bold);
    text-align: center;

    & span {
        color: color(golden-yellow);
    }
}
.header-bg-container {
    height: 220px;
    background-color: color(dark);
    background-image: url('./../../assets/images/search-bg.png');
    background-position: center;
    background-position-y: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
}
.para-black-container {
    margin-bottom: 25px;
    color: color(white5);
    
    & a {
        color: color(golden-yellow);
        
    }
    & strong {
        font-weight: 700;
        color: color(white7);
    }

    & p {
        font-weight: 600;
    }

    & pre {
        color: color(white7);
        margin-bottom: 15px;
    }
    
    & h1,
    & h2,
    & h3 {
        color: color(light);
        margin-bottom: 15px;

    }
    & h1 {
        font-size: size(xxxl);
    }
    & h2 {
        font-size: size(xxl);
    }
    & h3 {
        font-size: size(xl);
    }
    & ul,
    & ol {
        margin-bottom: 15px;

        & li {
            // background-color:red;
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 500;
        }
    }
    

    & h4 {
        font-size: size(large);
        font-weight: weight(bold);
        margin-bottom: 15px;
    }
    & p {
        // color: color(white5);
        font-size: size(regular);
        font-weight: weight(medium);
        margin-bottom: 15px;
    }
}
.privacy-page {
    padding-bottom: 200px;
    padding-top: 60px;
}
.app-theme-light {
    .header-bg-container {
        background-color: color(light-background2);
    }
    .highlited-title {
        color: color(dark);
    }
    // .para-black-container {
    //     & h4 {
    //         color: color(dark)
    //     }
    //     & p {
    //         color: color(black5);
    //     }
    // }
    .para-black-container {
        color: color(black5);
        
        & a {
            color: color(golden-yellow);
            
        }
        & strong {
            color: color(black7);
        }
    
        & pre {
            color: color(black7);
        }
        
        & h1,
        & h2,
        & h3 {
            color: color(dark);
    
        }
    }
}

#app-locale-ar {
    & .para-black-container,
    & .highlited-title {
        text-align: right;
    }
}

// @media (max-width: 575.98px) {
//     .highlited-title {
//         text-align: center;
//     }
// }
