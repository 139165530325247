@import '../../scss_partials/config';


// Search not found styles
.search-not-found-container {
    display: flex;
    justify-content: center;
    height: 300px;
    align-items: center;
    flex-direction: column;
    color: color(white7);
    width: 100%;
    padding-top: 50px;
    font-weight: 500;

    & div {
        margin-top: 10px;
    }

    & img {
        height: 220px;
        width: 100%;
        object-fit: contain;
    }
}

// RoundedBtn styles

.direction-btn-container {
    cursor: pointer;
    border: none;
    padding: 15px 20px;
    border-radius: 30px;
    color: #909090;
    font-size: 14px;
    margin: 0 7.5px;
    font-weight: 700;
    width: 120px;
    display: flex;
    justify-content: center;
    background-color: color(background3);

    &:hover {
        text-decoration: none;
        color: #909090;
    }

    & svg {
        margin-left: 5px;
        // margin-right: 5px;
        font-size: 12px;
    }
    & .prev-icon {
        margin-left: 0px;
        margin-right: 5px;
    }
}

.app-theme-light {

    & .search-not-found-container {
        color: color(black7);
    }
    & .direction-btn-container {
        background-color: transparent;
        border: 2px solid color(light-background3);
    }

}

#app-locale-ar {
    & .direction-btn-container {
        & svg {
            margin-right: 5px;
            margin-left: 0px;
            font-size: 12px;
        }
        & .prev-icon {
            margin-right: 0px;
            margin-left: 5px;
        }
    }
}