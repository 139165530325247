@import '../../scss_partials/config';
@import './contact.scss';

@media (max-width: 575.98px) {
    .contact-page {

        & .contact-form-container {
            padding-left: 0px;
            padding-right: 0px;

            & .address-container {
                padding-left: 0px;
                padding-right: 0px;
                margin-top: 25px;

                & img {
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    width: 80%;
                }
            }
        }

        & .download-wrapper {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

#app-locale-ar {
    @media (max-width: 575.98px)  {
        .contact-page .contact-form-container .address-container {
            padding-right: 5px;
        }
    }
}