@import './../../scss_partials/config';

.banner-container {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    & .banner-overlay-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        padding: 25px;
        flex-direction: column;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
        background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

        & h5 {
            font-size: size(xl);
            font-weight: weight(bold);
        }
        & h6 {
            font-size: size(medium);
        }
    }
}

#app-locale-ar {
    .banner-container {
        .banner-overlay-container {
            transform: scaleX(-1);
            text-align: right;
        }
        img {
            transform: scaleX(-1);
        }
    }
}