@import './../../scss_partials/config';

.contact-page {
    & .download-wrapper {
        padding-top: 0px;
        padding-bottom: 250px;
        background-color: color(background2);
        position: relative;
    }
    & .contact-form-container {
        padding-top: 70px;
        padding-bottom: 250px;
        background-color: color(background2);

        & button {
            background-color: color(dark);
            height: 60px;
            padding: 0px 100px;
            display: flex;
            align-items: center;
            border: 1px solid #F4C85E;
            border-radius: 5px;
        }

        & .address-container {
            padding-left: 100px;
            & .social-media-container {
                padding-left: 40px;
                padding-right: 40px;
                margin-bottom: 25px;
            }

            & .social-media {
                border-radius: 8px;
            }

            & img {
                // width: 100%;
                height: 250px;
                margin-left: 35px;
                margin-right: 35px;
            }
            & .address-content-container {
                padding: 15px;
                display: flex;
                font-size: size(regular);
                color: color(white7);
                font-weight: weight(medium);
                border-radius: 5px;
                transition:all ease 300ms;

                &:first-child svg {
                    color: color(golden-yellow);
                    transform: scaleX(-1);
                }
                &:nth-child(2) svg {
                    color: color(pinkk);
                }
                &:last-child svg {
                    color: color(olive-green);
                }
                
                &:hover {
                    background-color: color(dark);
                    // transform: translateX(10px)
                }
                
                & label {
                    // background-color: red;
                    margin: 0px;
                    cursor: pointer;
                    margin-right: 15px;
                    margin-left: 15px;
                }
                & a {
                    font-size: size(regular);
                    color: color(white7);
                    font-weight: weight(medium);
                }

                // & span {
                //     margin-right: 15px;
                // }
            }
        }

        & .form-container {
            & .form-group {
                // background-color: #fff;
                margin: 0px;
            }

            & .error {
                border: 1px solid #FF6565;
            }

            & .error-message {
                color: #FF6565;
                margin-top: 5px;
            }

            & .form-group {
                margin-bottom: 20px;
            }

            & input {
                background-color: color(main-background);
                border: none;
                height:70px;
                font-size: size(regular);
                color: color(light);
                font-weight: weight(bold);
                border-radius: 8px;
                padding-left: 25px;
                padding-right: 25px;
            }
            & textarea {
                background-color: color(main-background);
                border: none;
                font-size: size(regular);
                color: color(light);
                font-weight: weight(bold);
                border-radius: 8px;
                padding-top: 25px;
                padding-left: 25px;
            }
            & .btn-container {
                & div {
                    height: 56px;
                    padding: 0 100px;
                    background-color: #0E0E0E;
                }
            }
        }

        & h4{
            font-size: size(xxl);
            margin-bottom: 50px;
            color: color(light);
            font-weight: 800;
        }
    }
    & .assistive-banner-wrapper {
    
        .assistive-banner-container {
            & .assistive-banner-content-container {
                & h4 {
                    // font-size: 32px;
                    margin-bottom: 25px;
                    width: 100%;
                    // line-height: 1.7;
                }
            }
            & .btn-container {
                & div {
                    background-color: #0E0E0E;
                    height: 70px;
                }
            }
            & .golden-right-arrow {
                height: 15px;
                width: auto;
            }
        }
    }
}

.app-theme-light {
    .contact-page {
        & .header-container {
            background-image: url('./../../assets/images/contact-bg-light.svg') !important;
        }
        & .download-wrapper {
            background-color: color(light-main-background);
        }
        & .contact-form-container {
            background-color: color(light-main-background);
    
            & .address-container {
                & .address-content-container {
                    color: color(black7);
                    &:hover {
                        background-color: color(light);
                    }
                }
            }
            & .form-container {
                & input {
                    background-color: color(light-background2);
                    color: color(dark);
                }
                & textarea {
                    background-color: color(light-background2);
                    color: color(dark);
                }
                // & .btn-container {
                //     & div {
                //         background-color: #0E0E0E;
                //     }
                // }
            }
            & h4{
                color: color(dark);
            }
            & button {
                color: color(dark);
                background-color: transparent;
                border-width: 2px;
            }
        }
        & .assistive-banner-wrapper {

            .assistive-banner-container {
                & .btn-container {
                    & div {
                        background-color: transparent;
                        height: 70px;
                        border-width: 2px;
                    }
                }

                & .golden-right-arrow {
                    filter: brightness(0);
                }
            }
        }
        // & .assistive-banner-wrapper {
        //     .assistive-banner-container {
        //         & .btn-container {
        //             & div {
        //                 background-color: #0E0E0E;
        //             }
        //         }
        //     }
        // }
    }
}

#app-locale-ar {
    & .contact-page {
        & .contact-form-container {
           text-align: right;
            & .address-container .social-media {
                margin-left: 10px;
                margin-right: 0px;
            }
            & .address-container img {
                transform: scaleX(-1);
            }
            & .address-container {
                padding-left: 0px;
                padding-right: 100px;
            }
        }
    }
}