@import './../../scss_partials/config';
@import './Faq.scss';

@media (max-width: 575.98px) {
    .header-container {
        height: 200px;

        & .header-content-container {
            & h1 {
                margin-bottom: 15px;
            }
            & p, & h1 {
                width: 100%;
            }
        }
    }
    .assistive-banner-wrapper .assistive-banner-container {
        & img {
            width: 90%;
        }
        & .assistive-banner-content-container h4 {
            width: 100%;
        }
    }
    .faq-page-container {
        & .faq-page-content-container .faq-container {
            margin-top: 25px;
        }
        & .faq-page-content-container .download-banner-wrapper {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}