@import './../../scss_partials/config';

.for-experts-container {

    & .header-banner-container {
        height: calc(120vh - 82px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: hidden;
        background-color: color(background2);
        // position: relative;
        padding-bottom: 200px;
        min-height: 650px;

        & h1 {
            color: color(light);
        }
        
        & .header-banner-content-container {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            z-index: 1;
            
            & .underlay-forexperts-container {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                // background-color: red;
                z-index: -1;
                display: flex;
                justify-content: center;
                align-items: center;

                & img {
                    width: 75%;
                    height: 75%;
                    object-fit: contain;
                }
            }
            & .btn-container div {
                font-size: size(regular);
                padding: 0px 25px;
            }
        }

        & .card-wrapper {
            position: absolute;
            display: block;
            transform: scale(0.9);
            z-index: 10;
            /* left: 50px;
            bottom: 50px; */

            & .expert-card-wrapper {
                width: 170px;
            }
        }
        & .card2, & .card4 {
            transform: scale(0.81);
        }


        & h1 {
            text-align: center;
            font-size: size(xxxxl);
            font-weight: weight(heavy);
            margin: 0px;
        }

        & p {
            font-weight: weight(medium);
            font-size: size(medium);
            color: color(white7);
            margin: 20px 0px;
            margin-bottom: 25px;
        }
    }

    & .wrapper-container {
        padding-bottom: 70px;
        padding-top: 70px;

        & h5 {
            font-size: size(xxl);
            font-weight: weight(bold);
            margin-bottom: 70px;
            color: color(light);
            text-align: center;
        }
    }

    & .features-section {
        background-color: color(main-background);
        color: color(light);

        & .features-row {

            & .features-list {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;

                &:last-child {
                    text-align: left;
                    justify-content: flex-start;
                    // background-color: red;

                    ul li {
                        // background-color: blue;
                        justify-content: flex-end;
                        flex-direction: row-reverse;

                        & span {
                            margin-right: 20px;
                            margin-left: 0px;
                        }
                    }
                }

                & ul {
                    list-style: none;
                    padding: 0;

                    & li {
                        padding-bottom: 50px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        font-weight: 600;
                        font-size: 20px;

                        & span {
                            height: 45px;
                            width: 45px;
                            display: flex;
                            margin-left: 20px;
                            // background-color: red;

                            & img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
            & .features-mob-container {
                display: flex;
                justify-content: center;

                & img {
                    height: auto;
                    width: 80%;
                    object-fit: contain;
                }
            }
        }
        
    }

    & .register-benefits-section {
        background-color: color(background2);
        & img {
            height: 70px;
            width: auto;
            object-fit: contain;
        }
        & h6 {
            font-size: 20px;
            font-weight: weight(heavy);
            margin-bottom: 10px;
            margin-top: 25px;
            color: color(light);
        }
        & p {
            font-size: size(regular);
            color: color(white7);
            margin: 0px;
            width: 80%;
            font-weight: 500;
        }

        & .benefits-col-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
        }
        & .steps-arrow {
            color: color(golden-yellow);
        }
    }
}

.app-theme-light {
    & .for-experts-container {
        & .header-banner-container {
            background-color: color(light-background2);
        }
        & .header-banner-container h1 {
            color: color(dark);
        }
        & .header-banner-container p {
            color: color(black7);
        }
        & .features-section {
            background-color: color(light-main-background);
            color: color(dark);
        }
        & .register-benefits-section {
            background-color: color(light-background2);
        }
        & .wrapper-container h5 {
            color: color(dark);
        }
        & .register-benefits-section h6 {
            color: color(dark);
        }
        & .register-benefits-section p {
            color: color(black7);
        }
        & .register-benefits-section {
            & .steps-arrow {
                color: color(pinkk);
            }
        }
    }
}

#app-locale-ar {
    & .for-experts-container  {
        & .features-section .features-row .features-list ul li span {
            margin-left: 0px;
            margin-right: 20px;
        }
        & .features-section .features-row .features-list:last-child ul li span {
            margin-right: 0px;
            margin-left: 20px;
        }
        & .wrapper-container h5 {
            text-align: right;
        }
        & .section-title {
            text-align: right;
        }
    }
}
