@import './../../scss_partials/config';

.expert-card-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: all ease 300ms;
    transform-origin: top center;
    width: 165px;
    position: relative;
    &:hover .hover-icon-container {
        opacity: 1;
        transition: all ease 200ms;
    }
    &:hover .hover-overlay {
        background-color: color(pinkk);
        mix-blend-mode: screen;
    }

    &:hover {
        text-decoration: none;
        // transform: scale(1.1);
        // cursor: url('./../../assets/images/rounded-cursor-next.png'), auto;
    }
    & .card-img-wrapper {
        position: relative;
        isolation: isolate;
        height: 100%;
        width: 100%;
        overflow: hidden;
        border-radius: 15px;
        & img {
            height: 214px;
            width: 100%;
            background-color: #333;
            // border: 1px solid yellow;
            box-shadow: none;
            object-fit: cover;
            border-radius: 15px;
        }
        & .hover-overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    & .dark-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        border-radius: 14px;
        flex-direction: column;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.8981967787114846) 100%);
    }
    & h5 {
        font-size: size(medium);
        font-weight: weight(bold);
        margin-bottom: 0px;
        color: color(light);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        padding: 0 11px;
        
    }
    & .inside-header {
        position: absolute;
        bottom: 5px;
        left: 10px;
        overflow: hidden;
        white-space: nowrap;
        max-width: 92%;
        text-align: left;
        color: #fff;
    }
    & label {
        font-size: size(sm);
        color: color(white5);
        font-weight: weight(medium);
        margin: 0px;
    }
    & .hover-icon {
        width: 18px;
        height: auto;
        color: color(pinkk);
    }
    & .hover-icon-container {
        opacity: 0;
    }
}

.expert-card-container:hover h5, .expert-card-container:hover label {
    text-decoration: none;
}

.app-theme-light {
    & .expert-card-container {
        & h5  {
            color: color(dark);
        }
        & label {
            color: color(black5);

        }
        & .inside-header {
            color: #fff;
        }
    }
}