@import './../../scss_partials/config';

.not-found-container {
    height: calc(100vh + 81px);
    background-color: color(category-bg);

    & .four-not-four-container {
        display: flex;
        justify-content: center;
        flex-direction: column;

        & img {
            height: 430px;
        }
        & svg {
            color: color(pinkk);
            margin-right:10px;
        }
        & a {
            text-align: center;
            font-size: size(large);
            // margin-top: 40px;
            color: color(white7);
            // margin-left: 10px;
            font-weight: 500;
            border: 1px solid color(pinkk);
            border-radius: 5px;
            padding: 10px 20px;
            transition: all ease 200ms;

            &:hover {
                background-color: color(pinkk);
                color: color(dark);
                text-decoration: none;

                & svg {
                    color: color(dark);
                }
            }
        }
        & .four-not-four-illustration {
            display: flex;
            justify-content: flex-end;

            & img {
                // width: 100%;
                height: 250px;
                margin-top: -20px;
            }
        }
    }
}

.app-theme-light {
    & .not-found-container {
        background-color: color(light);
        & a {
            color: color(black7);
            &:hover {
                color: color(light);
    
                & svg {
                    color: color(light);
                }
            }
        }
    }
}