@import '../../scss_partials/config';
// @import './home.scss';


@media (max-width: 575.98px) {
    .videos-section .video-card-wrapper {
        margin-bottom: 15px;
    }
    & .download-banner-container {
        // background-color:red;
        padding: 25px;
    }
    .testimonial-section {
        & .testimonial-section-container {
            padding: 0px;
        }

    }
    & .videos-section-container {
        // background-color: red;
        padding: 0px;
    }
    .home-container {
        padding-right: 0px;
        padding-bottom: 220px;
        padding-top: 10px; /*navbar height*/
        padding-left: 0px;

        & .platform-download-container {
            margin-bottom: 15px;
        }
        & .slider-container {
            height: auto;
            margin-top: 30px;
            margin-bottom: 50px;
    
            & .slider-content-container {
                & .slider-info-container {
                    & h2 {
                        margin: 0px;
                    }
                    & p {
                        margin: 15px 0px;
                        margin-top: 10px;
                    }
                }
                & .slides-container {
                    & .slides-wrapper {
                        margin-right: 10px;
                        width: 90vw!important;
                    }
                }
            }
        }
    }
    & .features-section {
        padding-bottom: 270px;
        & .features-container {
            padding: 0px;
        }
        .slick-arrow-prev, .slick-arrow-next {
            bottom: -70px;
        }
        .slick-arrow-next {
            left: 65px;
        }
    }
    & .experts-section {
        & .slick-arrow-prev, .slick-arrow-next {
            // top: -90px;
            top: 300px;
        }
        & .slick-arrow-next {
            right: 15px;
        }
        & .slick-arrow-prev {
            right: 80px;
        }
    }
    & .session-section {
        padding: 0px;

        & .session-section-content-container {
            & .slick-slider {
                // background-color: red;
                margin-top: -50px;
            }
        }
        & .category-container {

            & .slick-arrow-prev {
                left: -15px;
            }
            & .slick-arrow-next {
                right: -15px;
            }

            & .category-prev-arrow-bg {
                background: transparent;
            }
            & .category-next-arrow-bg {
                background: transparent;
            }
        }
        & .category-slider {
            margin: 0px;
        }
    }
    & .home-page .category-card {
        margin: 0px;
        margin-right: 10px;
    }

    & .article-container {
        // background-color: #fff;
        & .article-row-container {
            padding-right: 0px;
        }
        & .article-row-container {
            // background-color: red;
            margin-left: 0px !important;
            margin-right: 0px !important;
            padding: 0px;
        }
        & .article-col-container {
            padding: 0px;    
        }
        & .article-card-container {
            margin-right: 15px;
        }
        .arrows-bottom .slick-dots {
            display: none;
        }
    }
    & .learn-more-container {
        padding: 0px;
    }
    & .about-walsos-section {
        & .section-title {
            margin-bottom: 25px;
        }
        & .video-card-wrapper {
            width: 85vw !important;
            margin-right: 15px;

            & .video-card-overlay {
                // background-color: red;
                padding: 15px !important;

            }
        }
    }
}
#app-locale-ar {
    @media (max-width: 575.98px) {
        .home-container .slider-container .slider-content-container .slides-container {
            padding-right: 0px !important;
            // background-color:red;
        }
        .slider-info-container {
            padding-right: 0px;
        }
    }
}
