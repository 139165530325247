@import '../../scss_partials/config';
@import './article-details.scss';

@media (max-width: 575.98px) {
    .article-detail-page {
        & .small-container {
            padding-left: 0px;
            padding-right: 0px;
            & .article-img-container img {
                height: 200px;
            }
        }

        & .article-container .arrows-bottom .article-card-container {
            margin: 0px;
        }
        & .related-article-card-container {
            padding-left: 0px;
            padding-right: 0px;
        }
        & .article-card-container .article-content-container {
            padding: 15px;
        }
    }
}
#app-locale-ar {
    @media (max-width: 575.98px) {
        .footer-bg div h4 {
            text-align: right;
        }
        .footer-bg .footer-block-container:last-child {
            text-align: right;
        }
    }
}