@import './../../scss_partials/config';
@import './Session.scss';

@media (max-width: 575.98px) {
    .session-info-container {
        & .btn-container {
            width: 100%;
        }
        & .btn-container div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
        }
        & .book-now-container {
            flex-direction: column;
            // background-color: red;
            padding: 20px 15px;

            & .price-container {
                // background-color: red;
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
    .session-detail-container {
        & .left-col-container {
            & .social-media-container {
                background-color: red;
                display: none;
            }
        }
    }
}