@import './../../scss_partials/config';
@import './expert-details.scss';

@media (max-width: 575.98px) {
    .detail-page .detail-container .content-col {
        padding: 25px 15px;
    }
    .expert-detail-page {
        & .dashboard-container {
            justify-content: space-around;
        }
        & .social-media-container {
            margin-bottom: 20px;
        }
    }
}